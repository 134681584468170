import { environment } from '../../../../environments/environment';
import { isErrorResponse, network } from '../network';
import { FacilityUpdateStatusEnum, ICreateFacilityObj } from 'app/react/types/facility';
import { MediaItem } from 'app/react/types/media';
import { imageApi } from './imageApi';
import { FacilitySection } from 'app/react/forms/settings/facility/types';
import { SectionEnum } from 'app/react/types/settings';
import { ECalendarMode } from '../../stores/calendarStore';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as weekday from 'dayjs/plugin/weekday';
import { HasStartAndEndDates, ICalendarSlotCard } from '@bondsports/types';
import { DateTimeFormats, MIDNIGHT, DateInput } from '@bondsports/date-time';
import { IBasicError } from '@app/react/types/errors';
import { sumBy } from 'lodash';

dayjs.extend(utc);
dayjs.extend(weekday);

const facility_Url = `${environment.CS_URLS.API_ROOT_V4}/facilities/organization`;

const getDatesByMode = (date: string, mode: ECalendarMode): HasStartAndEndDates => {
	let startDate: string = dayjs.utc(date).date(1).format(DateTimeFormats.YYYY_MM_DD);
	let endDate: string = dayjs.utc(date).date(31).format(DateTimeFormats.YYYY_MM_DD);

	switch (mode) {
		case ECalendarMode.DAILY: {
			startDate = date;
			endDate = date;

			return { startDate, endDate };
		}
		case ECalendarMode.WEEKLY: {
			// Sunday is 0
			if (!dayjs(date).day()) {
				startDate = dayjs.utc(date).weekday(1).subtract(1, 'week').format(DateTimeFormats.YYYY_MM_DD); // should be 1
				endDate = dayjs.utc(date).weekday(0).format(DateTimeFormats.YYYY_MM_DD); // should be 0
			} else {
				startDate = dayjs.utc(date).weekday(1).format(DateTimeFormats.YYYY_MM_DD); // should be 1
				endDate = dayjs.utc(date).weekday(0).add(1, 'week').format(DateTimeFormats.YYYY_MM_DD); // should be 0
			}

			return { startDate, endDate };
		}
	}

	return { startDate, endDate };
};

const transformSlot = (slot: any, date?: DateInput) => {
	slot.startDate = dayjs.utc(slot.startDate).format(DateTimeFormats.YYYY_MM_DD);
	slot.endDate = dayjs.utc(slot.endDate).format(DateTimeFormats.YYYY_MM_DD);

	slot.children = slot.children?.map(child => transformSlot(child, date)) ?? [];

	slot.conflictsCount ??= 0;
	slot.conflictsCount += sumBy(slot.children as any[], child => slot.conflictsNumber ?? child.conflictsCount ?? 0);

	return slot;
};

const getSpacesByDay = async (organizationId: number, facilityId: number, date?: string, mode?: ECalendarMode) => {
	const { startDate, endDate } = getDatesByMode(date, mode);

	const response: IBasicError | any[] = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/reservations/organization/${organizationId}/facility/${facilityId}?startDate=${startDate}&endDate=${endDate}&limit=5000&onlyActiveSessions=true&includeBlocking=true&checkConflicts=true&resourcesTypes=space&includeActivityTimes=true`
	);

	if (isErrorResponse(response)) {
		return response;
	}

	return response.map(resource => {
		const slotsToShow = new Map<number, any>();
		for (const slot of resource.slots) {
			const transformedSlot = transformSlot(slot, date);

			if (
				!(transformedSlot.endTime === MIDNIGHT && date === transformedSlot.endDate) ||
				!!transformedSlot.children.length
			) {
				slotsToShow.set(transformedSlot.id, transformedSlot);
			}
		}

		resource.slots = Array.from(slotsToShow.values());
		return resource;
	});
};

const getGroupsByFacilityId = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/rentals/organization/${organizationId}/resource-group/facility/${facilityId}`
	);
	return response;
};

const getPackages = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/packages?creatorId=${facilityId}&creatorType=venue`
	);
	return response;
};

const getVenuePaymentToken = async (organizationId: number, provider = 'stripe') => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/payments/client-token?provider=${provider}&id=${organizationId}&type=organization`
	);
	return response;
};

const updateBooking = async (reservationId: number, data: any) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT_V2}/reservations/${reservationId}`, data);
	return response;
};

const cancelBooking = async (reservationId: number) => {
	const response = await network.delete(`${environment.CS_URLS.API_ROOT_V2}/reservations/${reservationId}`, {});
	return response;
};

const updateSession = async (sessionId: number, data: any) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT_V2}/reservations/sessions/${sessionId}`, data);
	return response;
};

const cancelSession = async (sessionId: number) => {
	const response = await network.delete(`${environment.CS_URLS.API_ROOT_V2}/reservations/sessions/${sessionId}`, {});
	return response;
};
const createFacility = async (organizationId: number, data: ICreateFacilityObj) => {
	const response = await network.post(`${facility_Url}/${organizationId}`, data);
	return response;
};

const updateFacilityStatus = async (organizationId: number, facilityId: number, status: FacilityUpdateStatusEnum) => {
	const response = await network.put(`${facility_Url}/${organizationId}/${facilityId}/${status}`, {});
	return response;
};

const updateFacilityData = async (
	organizationId: number,
	facilityId: number,
	section: SectionEnum,
	data: FacilitySection
) => {
	const response = await network.put(`${facility_Url}/${organizationId}/${facilityId}/${section}`, data);
	return response;
};

const uploadFacilityMedia = (file: MediaItem, facilityId: number) => {
	return imageApi.uploadFileItemImage(file).then(async response => {
		const fileObject = {
			url: response.secure_url,
			provider: 'cloudinary',
			fileType: response.format,
			mediaKey: response.public_id,
			fileName: response.original_filename,
		};
		return await network.post(`${environment.CS_URLS.API_ROOT}/venues/${facilityId}/uploadMedia?handleType=main`, {
			file: fileObject,
			//  handleType: type,
		});
	});
};

const getFacilityById = async (organizationId: number, facilityId: number) => {
	const response = await network.get(`${facility_Url}/${organizationId}/${facilityId}`, {});
	return response;
};

const getCalendarSlotById = async (
	organizationId: number,
	reservationId: number,
	slotId: number
): Promise<ICalendarSlotCard> => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/reservations/${reservationId}/organization/${organizationId}/slot/${slotId}/calendar-card`
	);
	return response;
};

const getResourcesByFacilityID = async (organizationId: number, facilityId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/resources/organization/${organizationId}/facility/${facilityId}`
	);
	return response;
};

export const facilityApi = {
	getResourcesByFacilityID,
	getVenuePaymentToken,
	getPackages,
	getSpacesByDay,
	updateBooking,
	cancelBooking,
	updateSession,
	getCalendarSlotById,
	cancelSession,
	getGroupsByFacilityId,
	createFacility,
	uploadFacilityMedia,
	getFacilityById,
	updateFacilityData,
	updateFacilityStatus,
};
