import { CancellationStatusEnum, MembershipStatusEnum, PaymentStatusEnum } from '@bondsports/types';
import { MediaInterface } from './media';
import { Product, ProductPackage } from './product';
import { ReactNode } from 'react';
import { IUpdatePauseMembershipMember, IPauseMembershipMember } from '../forms/membership/pause/createPause/types';

export enum CustomerTypeEnum {
	NONE = 'none',
	INDIVIDUAL = 'individual',
	FAMILY = 'family',
	ORGANIZATION = 'organization',
}

export enum MembershipTypeEnum {
	FIXED = 'fix_membership',
	ROLLING = 'rolling_membership',
}

export interface Membership {
	id: number;
	organizationId: number;
	name: string;
	description?: string;
	mainMedia?: MediaInterface;
	customerTypes: CustomerTypeEnum[];
	numberOfPeople?: number;
	activity: number;
	facilityId: number;
	facilityName?: string;
	isAutoRenew?: boolean;
	questionnaires: number[];
	minAgeYears: number;
	maxAgeYears: number;
	gender: number;
	tags: string[];
	maxMembers?: number;
	maxMaleMembers?: number;
	maxFemaleMembers?: number;
	membershipType: MembershipTypeEnum;
	startDate: string;
	endDate: string;
	registrationStartDate: string;
	registrationEndDate: string;
	durationMonths?: number;
	userId?: number;
	package: {
		parentProduct: Product; // the membership product will include currPrice and prices[] inside, as in all products
		children: {
			product: Product;
			relationType: ProductPackage;
		}[];
	};
}

export class CreateMembershipDto {
	organizationId: number;
	name: string;
	description?: string;
	customerTypes: CustomerTypeEnum[];
	activity?: number; // SportsEnum;
	facilityId?: number;
	questionnaires?: number[];
	minAgeYears?: number;
	maxAgeYears?: number;
	gender: number; // GenderEnum;
	maxMembers?: number;
	maxMaleMembers?: number;
	maxFemaleMembers?: number;
	membershipType: MembershipTypeEnum;
	startDate?: string;
	endDate?: string;
	registrationStartDate?: string;
	registrationEndDate?: string;
	durationMonths?: number;
}

export class UpdateMembrshipDto extends CreateMembershipDto {
	id: number;
}

export enum MembershipStatusFilterEnum {
	ACTIVE = 'active',
	CANCELED = 'cancelled',
	EXPIRED = 'expired',
	PENDING = 'pending',
}

/**
 * only values of this enum will be displayed on the filter (the BE enum contain more values that arn't needed atm...)
 */
export enum PaymentStatusFilterEnum {
	NOT_PAID = 'not_paid',
	PARTIAL_PAYMENT = 'partial',
	FULLY_PAID = 'paid',
	REFUNDED = 'refunded',
	VOID = 'void',
}

export type TMembershipFilterValue = MembershipStatusEnum | PaymentStatusEnum | MembershipTypeEnum;

/**
 * this enum is all caps beacause it used for map MembershipOrderByPropertyEnum
 */
export enum MembershipTabSortableColumnsEnum {
	ENDDATE = 'ENDDATE',
	MEMBERSHIP = 'MEMBERSHIP',
	MEMBERSHIP_STATUS = 'MEMBERSHIP_STATUS',
	NAME = 'NAME',
	PAYMENT_STATUS = 'PAYMENT_STATUS',
	RENEWAL = 'RENEWAL',
	STARTDATE = 'STARTDATE',
}

export enum ColumnsIDsEnum {
	NAME = 'name',
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	RENEWAL = 'renewal',
	PRICE = 'price',
	INVOICE_ID = 'invoiceId',
	PAYMENT_ID = 'paymentId',
	STATUS = 'status',
	MORE = 'more',
	CUSTOMER_NAME = 'customerName',
}

export interface IMembershipRow {
	id: number;
	membershipId: number;
	memberId: number;
	userId: number;
	durationMonths?: number;
	renewal: string;
	startDate?: string;
	endDate?: string;
	pauseStartDate?: string;
	pauseEndDate?: string;
	cancelledAt?: string;
	isAutoRenew?: boolean;
	cancellationReason?: string;
	cancellationStatus?: string;
	membershipStatus?: MembershipStatusEnum;
	firstName?: string;
	lastName?: string;
	name?: string;
	paymentStatus?: PaymentStatusEnum;
	price: number;
	paidAmount?: number;
	customerName: string;
	invoiceId: number;
	paymentId: number;
	customerTypes: CustomerTypeEnum[];
	withPaymentStatusIcon: boolean;
	additionalDate?: string;
	additionalDatePrfix?: string;
	TagComponent: ReactNode | null;
	taggedColumnId: ColumnsIDsEnum;
	more: {
		membershipStatus: MembershipStatusEnum;
		cancellationStatus: CancellationStatusEnum;
		isAutoRenew: boolean;
		isDisabled: boolean;
		hasPauseHistory: boolean;
	};
	hasPauseHistory: boolean;
}

export interface IMoreButtonState {
	membershipStatus: MembershipStatusEnum;
	cancellationStatus: CancellationStatusEnum;
	isDisabled: boolean;
	isAutoRenew: boolean;
}

export enum MembershipMoreButtonOptionEnum {
	CANCEL = 'cancel',
	PAUSE = 'pause',
	EDIT_PAUSE = 'edit_pause',
	UNDO_PAUSE = 'undo_pause',
}

export interface IPauseParams {
	organizationId: number;
	pauseData: IPauseMembershipMember;
	pausedMemberId: number;
	familyMembership: boolean;
	callback: () => Promise<void>;
}

export interface IEditPauseParams {
	organizationId: number;
	familyMembership: boolean;
	editPauseData: IUpdatePauseMembershipMember;
	pausedMemberId: number;
	callback: () => Promise<void>;
}

export interface IUnpauseParams {
	organizationId: number;
	pausedMemberId: number;
	familyMembership: boolean;
	callback: () => Promise<void>;
}