import { IErrorArr } from '@app/react/types/errors';
import { IGetGlCodesQuery } from '@app/react/types/organization';
import { IOrganizationPaymentMethod } from '@app/react/types/payment';
import { Customer, Event, GlCodeDto, IPaginationData } from '@bondsports/types';
import { QueryDataParams, SearchParams } from '@bondsports/utils';
import dayjs from 'dayjs';
import { isUndefined } from 'lodash';
import { environment } from '../../../../environments/environment';
import { ISelectedFilters, IUnallocatedEventRes } from '../../components/allocation/types';
import {
	CreateOrgnizationData,
	OrgnizationMainDetails,
} from '../../forms/settings/organization/createOrganizationProfile/types';
import { PlatformsEnum } from '../../types/orders';
import { EReservationStatus } from '../../types/reservation';
import { auth } from '../auth';
import { network } from '../network';
import { buildQueryString } from '../urlUtils';
import { MediaItem } from './../../types/media';
import { imageApi } from './imageApi';

const v4Api = `${environment.CS_URLS.API_ROOT_V4}`;
const defaultsPageSize = 12;

const ANON_LOCAL_KEY = 'anon-user';

const getOrganizationData = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}`);
	return response;
};

const getFacilitiesDepricated = async (organizationId: number, searchFilter?: string) => {
	const searchParam = searchFilter ? `?nameSearch=${encodeURIComponent(searchFilter)}` : '';

	const response = await network.get(`${v4Api}/facilities/organization/${organizationId}${searchParam}`);

	return response;
};

const getFacilities = async ({
	organizationId,
	queryData = { itemsPerPage: 8, page: 1, filters: { search: '' } },
	signal,
}: {
	organizationId: number;
	queryData: QueryDataParams<SearchParams>;
	signal?: AbortSignal;
}) => {
	const queryString = buildQueryString({ itemsPerPage: queryData.itemsPerPage, page: queryData.page });
	const searchParam = queryData?.filters?.search ? `&nameSearch=${encodeURIComponent(queryData?.filters?.search)}` : '';
	const response = await network.get(`${v4Api}/facilities/organization/${organizationId}${queryString}${searchParam}`, {
		signal,
	});

	return response;
};

const getSpaces = async (organizationId: number, facilityId: number) => {
	return network.get(`${v4Api}/resources/organization/${organizationId}/facility/${facilityId}`);
};

const getForms = async (organizationId: number) => {
	const headers = auth.getAuthHeaders();
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/questionnaires`,
		headers
	);
	return response;
};

const openShift = async (
	organizationId: number,
	data: {
		openingCashAmount: number;
		stationId: number;
	}
) => {
	const response = await network.post(`${v4Api}/shifts/organization/${organizationId}/open`, data);
	return response;
};

const closeShift = async (
	organizationId: number,
	shiftId: number,
	data: {
		closingCashAmount: number;
	}
) => {
	const response = await network.put(`${v4Api}/shifts/organization/${organizationId}/close/${shiftId}`, data);
	return response;
};

const validateApplications = async (organizationId: number, userId: number, questionnaireIds: number[]) => {
	let questionnaire = '';
	questionnaireIds.forEach((id, index) => {
		if (index != 0) {
			questionnaire += `,${id}`;
		} else {
			questionnaire += `${id}`;
		}
	});

	const response = await network.get(
		`${v4Api}/applications/user/${userId}/organization/${organizationId}/answers?&questionnaireIds=${questionnaire}`
	);
	return response;
};

const getAnonymousCustomer = async (organizationId: number) => {
	// Check local storage for anon user and confirm the organizationId is correct, or else fetch
	const item = localStorage.getItem(ANON_LOCAL_KEY);
	const anonCustomer: Customer & { issueTime: string } = JSON.parse(item);

	if (item && anonCustomer) {
		if (anonCustomer.organizationId === organizationId && !refreshAnon(anonCustomer.issueTime)) {
			return { data: anonCustomer };
		}
	}

	const anon = await getAnonCustomerFromApi(organizationId);
	if (anon) {
		// Save anon customer for future uses
		localStorage.setItem(ANON_LOCAL_KEY, JSON.stringify({ issueTime: dayjs().format('YYYYMMDD'), ...anon.data }));
		return anon;
	}
};

const refreshAnon = (issueTime: string) => {
	return dayjs().format('YYYYMMDD') !== issueTime;
};

const getAnonCustomerFromApi = async (organizationId: number): Promise<{ data: Customer }> => {
	return network.get(`${v4Api}/organization/${organizationId}/anonymous-customer`);
};

const getOrganizationCustomer = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}/organization-customer`);
	return response;
};

const getAddons = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/product-pricing/organization/${organizationId}/addons`);
	return response;
};
const getDiscounts = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}/entitlement-groups`);
	return response;
};

const updateOrganization = async (organizationId: number, data: OrgnizationMainDetails | CreateOrgnizationData) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}`, data);
	return response;
};

const updateOrganizationSports = async (organizationId: number, data, sports: number[]) => {
	const response = await network.put(`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}`, {
		...data,
		sports,
	});
	return response;
};

const uploadOrganizationMedia = (file: MediaItem, organizationId: number) => {
	return imageApi.uploadFileItemImage(file).then(async response => {
		const fileObject = {
			url: response.secure_url,
			provider: 'cloudinary',
			fileType: response.format,
			mediaKey: response.public_id,
			fileName: response.original_filename,
		};
		return await network.post(
			`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/uploadMedia?handleType=logo`,
			{
				file: fileObject,
			}
		);
	});
};

const removeOrganizationLogo = (organizationId: number, type: 'main' | 'logo' = 'logo') => {
	const fileObject = { url: '', provider: 'cloudinary', fileType: 'jpg', mediaKey: '', fileName: '' };

	return network.post(`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/uploadMedia?handleType=logo`, {
		file: fileObject,
	});
};
const getShiftsByStationId = async (organizationId: number, stationId: number) => {
	const response = await network.get(`${v4Api}/shifts/organization/${organizationId}/station/${stationId}`);
	return response;
};

interface IFindShiftsFilters {
	statuses?: string; // example: 'open,closed'
	stationIds?: string; // example: '3,5'

	openingUsersIds?: string; // example: '152,153'
	closingUsersIds?: string; // not used now, but I'm already adding them here
	closingManagersIds?: string; // not used now, but I'm already adding them here
	reconcilingUsersIds?: string; // not used now, but I'm already adding them here

	startDate?: string; // open dates range low limit. example: '2021-10-06'
	endDate?: string; // open dates range high range. example: '2021-10-10'
}

const getShifts = async (organizationId: number, queryData?: IFindShiftsFilters) => {
	const response = await network.get(`${v4Api}/shifts/organization/${organizationId}${buildQueryString(queryData)}`);
	return response;
};

const getShift = async (organizationId: number, shiftId: number) => {
	const response = await network.get(`${v4Api}/shifts/organization/${organizationId}/${shiftId}`);
	return response;
};

interface ShiftManagementClosingAmount {
	shiftId: number;
	managementClosingCashAmount: number;
}

interface IManagementPayload {
	managementClosingData: ShiftManagementClosingAmount[];
}

const managementUpdate = async (organizationId: number, data: IManagementPayload) => {
	const response = await network.put(`${v4Api}/shifts/organization/${organizationId}/management-closing`, data);
	return response;
};

const reconcile = async (organizationId: number, shiftIds: number[]) => {
	const response = await network.put(`${v4Api}/shifts/organization/${organizationId}/reconcile`, { shiftIds });
	return response;
};

const getCustomerById = async (organizationId: number, customerId: number) => {
	return await network.get(`${v4Api}/organization/${organizationId}/customer/${customerId}`);
};

const getAllReservations = async (
	organizationId: number,
	page = 1,
	status = '',
	resources: string[] = [],
	customerName = '',
	paymentStatus = '',
	itemsPerPage = defaultsPageSize,
	startDate = '',
	endDate = ''
) => {
	const startDateParam = startDate && `&startDate=${startDate}`;
	const endDateParam = endDate && `&endDate=${endDate}`;
	const response = await network.get(
		`${v4Api}/reservations/organization/${organizationId}?page=${page}&itemsPerPage=${itemsPerPage}&resourcesIds=${resources}${startDateParam}${endDateParam}&status=${status}&paymentStatus=${paymentStatus}&customerName=${customerName}`
	);
	return response;
};

const getSingleReservation = async (organizatioId: number, reservationId: number, buildTree = false) => {
	const response = await network.get(
		`${v4Api}/reservations/organization/${organizatioId}/${reservationId}?includeCustomer=true&includeAddons=true&includeInvoices=true&includePayments=true&includeSeries=true&includeAnswers=true&buildTree=${buildTree}`
	);
	return response;
};

const updateReservationStatus = async (
	organizatioId: number,
	reservationId: number,
	status: EReservationStatus,
	data: {
		shiftId?: number;
	}
) => {
	const payload = {
		...data,
		platform: PlatformsEnum.BO,
	};

	const response = await network.post(
		`${v4Api}/reservations/organization/${organizatioId}/reservation/${reservationId}/updateStatus/${status}`,
		payload
	);
	return response;
};

const getOrganizationById = async (organizationId: number) => {
	const response = await network.get(`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/?dataScope=full`);
	return response;
};

const getInvoicesByReservationId = async (
	organizationId: number,
	reservationId: number,
	statusFilter?: string[],
	methodFilter?: string[],
	searchFilter?: string,
	months?: string[]
) => {
	let searchParam = '';
	if (searchFilter != '') {
		searchParam = `&id=${encodeURIComponent(searchFilter)}`;
	}
	let statusParam = '';
	if (statusFilter.length != 0) {
		let query = '';
		statusFilter.map((status, index) => {
			if (index == 0) {
				query += status.toLowerCase();
			} else {
				query += `,${status.toLowerCase()}`;
			}
		});
		statusParam = `&paymentStatuses=${query}`;
	}
	let monthParam = '';
	if (months.length != 0) {
		let query = '';
		months.map((month, index) => {
			if (index == 0) {
				query += month.toLowerCase();
			} else {
				query += `,${month.toLowerCase()}`;
			}
		});
		monthParam = `&months=${query}`;
	}

	let methodParam = '';
	if (methodFilter.length != 0) {
		let query = '';
		methodFilter.map((method, index) => {
			if (index == 0) {
				query += method;
			} else {
				query += `,${method}`;
			}
		});
		methodParam = `&paymentTypes=${query}`;
	}

	const response = await network.get(
		`${v4Api}/reservations/organization/${organizationId}/reservation/${reservationId}/invoices?${statusParam}${methodParam}${searchParam}${monthParam}`
	);

	return response;
};

const getRefundReasons = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}/settings/refund-reasons`);
	return response;
};

const getPauseReasons = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}/settings/pause-reasons`);
	return response;
}

interface IGetUnallocatedEventsQuery {
	organizationId: number;
	programId?: number;
	sessionId?: number;
	segmentsIds?: number[];
	filters?: ISelectedFilters;
	type?: 'events' | 'ids';
}
const getUnallocatedEventIds = ({
	organizationId,
	programId,
	sessionId,
	segmentsIds,
	filters,
}: IGetUnallocatedEventsQuery): Promise<{
	data: IUnallocatedEventRes[];
}> => {
	const url = getUnallocatedEventsUrl({ organizationId, programId, sessionId, segmentsIds, filters, type: 'ids' });
	return network.get(url);
};

const getUnallocatedEvents = ({
	organizationId,
	programId,
	sessionId,
	segmentsIds,
	filters,
}: IGetUnallocatedEventsQuery): Promise<IPaginationData<Event>> => {
	const url = getUnallocatedEventsUrl({ organizationId, programId, sessionId, segmentsIds, filters, type: 'events' });
	return network.get(url);
};

const getUnallocatedEventsUrl = ({
	organizationId,
	programId,
	sessionId,
	segmentsIds,
	filters,
	type = 'events',
}: IGetUnallocatedEventsQuery): string => {
	let filterString = '';
	if (filters && Object.keys(filters).length > 0) {
		if (filters.day) {
			filterString = `${filterString}&dow=${filters.day.join(',')}`;
		}
		if (filters.month) {
			filterString = `${filterString}&months=${filters.month.join(',')}`;
		}
	}

	return `${v4Api}/organization/${organizationId}/unallocated-events${type === 'ids' ? '-ids' : ''}?${
		programId ? `programsIds=${programId}` : ''
	}&${sessionId ? `sessionsIds=${sessionId}` : ''}${
		segmentsIds?.length ? `&segmentsIds=${segmentsIds.join(',')}` : ''
	}${filterString}`;
};

const getOrganizationPaymentMethods = (
	organizationId: number,
	platform: PlatformsEnum = PlatformsEnum.BO
): Promise<IOrganizationPaymentMethod[] | { err: string }> => {
	const headers = auth.getAuthHeaders();
	return network.get(`${v4Api}/organization/${organizationId}/payment-methods?platform=${platform}`, headers);
};

const downloadInvoicePdf = async (userId: number, invoiceId: number, organizationId: number) => {
	return network.post(
		`${v4Api}/payment/user/${userId}/invoice/${invoiceId}/generate-pdf?organizationId=${organizationId}`,
		{}
	);
};

const downloadPaymentPdf = async (userId: number, paymentId: number, organizationId: number) => {
	return network.post(
		`${v4Api}/payment/user/${userId}/payment/${paymentId}/generate-pdf?organizationId=${organizationId}`,
		{}
	);
};

async function getGlCodes(organizationId: number, queryParam?: IGetGlCodesQuery): Promise<GlCodeDto[] | IErrorArr> {
	const query = parseQueryParamsToString(queryParam);
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/gl-codes/organization/${organizationId}?${query}`
	);
	return response;
}

const getQuestionnaire = async (organizationId: number, questionnaireId: number) => {
	//aviram
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/questionnaire/${questionnaireId}`
	);
	return response;
};

const getDiscountReasons = async (organizationId: number) => {
	const response = await network.get(`${v4Api}/organization/${organizationId}/settings/discount-reasons`);
	return response;
};

export const organizationApi = {
	getQuestionnaire,
	getOrganizationData,
	getFacilitiesDepricated,
	getAnonymousCustomer,
	getOrganizationCustomer,
	getOrganizationById,
	getShifts,
	validateApplications,
	getAddons,
	getForms,
	getInvoicesByReservationId,
	getDiscounts,
	getSpaces,
	reconcile,
	openShift,
	getShiftsByStationId,
	closeShift,
	updateOrganization,
	uploadOrganizationMedia,
	removeOrganizationLogo,
	getRefundReasons,
	getPauseReasons,
	managementUpdate,
	getShift,
	updateOrganizationSports,
	getAllReservations,
	getSingleReservation,
	updateReservationStatus,
	getCustomerById,
	getUnallocatedEventIds,
	getUnallocatedEvents,
	getUnallocatedEventsUrl,
	getOrganizationPaymentMethods,
	downloadInvoicePdf,
	downloadPaymentPdf,
	getGlCodes,
	getDiscountReasons,
	getFacilities,
};

function parseQueryParamsToString(queryParam: IGetGlCodesQuery) {
	return Object.keys(queryParam)
		.map(k => {
			if (isUndefined(queryParam[k])) {
				return;
			}
			const result = `${encodeURIComponent(k)}=`;
			if (Array.isArray(queryParam[k])) {
				return `${result}${encodeURIComponent(queryParam[k].join(','))}`;
			} else {
				return `${result}${queryParam[k]}`;
			}
		})
		.join('&');
}
