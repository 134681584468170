/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import React, { memo, useState } from 'react';
import { ETypography, ETypographyColor, Icons } from '../../types/theme';
import { Typography } from '../../atoms/Typography';
import { ThemeColors as colors } from '../../styles/theme';
import { Radio } from '../../components/Radio';
import { Tooltip } from '../../components/Tooltip';
import { BnIcon } from '../../components/icons_v2';

export enum ArchiveSlotInvoiceActionEnum {
	RETURN = 'return', // Take the returned items off the order
	REMOVE = 'remove', // Same as return
	REFUND_AND_VOID = 'refund-and-void',
	REFUND = 'refund', // Order not modified on refund - leave balance
	VOID = 'void', // Void line item on order - remove balance
	IGNORE = 'ignore', // Do nothing with the order - leave as is
}

const optionContainerCss = css`
	width: 100%;
`;

const actionSelectCss = css`
	margin-top: 8px;
	padding: 16px;
	background: ${colors.bg_background_primary};
	text-align: start;
`;

const actionItemCss = css`
	display: flex;
	align-items: center;
	svg {
		color: ${colors.bg_text_teriaty};
		height: 16px;
		width: 16px;
		vertical-align: middle;
	}
`;

interface ActionProps {
	actions: ArchiveSlotInvoiceActionEnum[];
	selectedAction?: ArchiveSlotInvoiceActionEnum;
	onSelect: (action: ArchiveSlotInvoiceActionEnum) => void;
}

export interface ActionSelectProps {
	actions: ArchiveSlotInvoiceActionEnum[];
	onActionSelect?: (action: ArchiveSlotInvoiceActionEnum) => void;
	explainer?: string;
}

const ActionSelection = memo(({ actions, selectedAction, onSelect }: ActionProps) => {
	const labels = {
		action: {
			return: 'Return',
			ignore: 'Leave invoice balance',
			refund: 'Refund & leave balance',
			remove: 'Refund & remove balance',
			'refund-and-void': 'Return',
			void: 'Void',
		},
		tooltip: {
			return: `The payment will be refunded, the line item amount will be reduced from the invoice. Remove Discounts to use 'Return' action`,
			'refund-and-void': 'The payment will be refunded, the line item amount will be reduced from the invoice.',
			ignore: 'The invoice will be left as is.',
			refund: 'The payment will be refunded to the customer and the invoice will be left as is.', 
			remove: `The payment will be refunded to the customer and the balance for these items will be removed. Remove Discounts to use 'Refund & remove balance' action`,
			void: 'The line item amount will be voided from the invoice.',
		},
	};

	const actionsOrder = Object.values(ArchiveSlotInvoiceActionEnum);
	return (
		<div css={actionSelectCss} data-aid="index-ActionSelect">
			{actions
				.sort((a, b) => actionsOrder.indexOf(a) - actionsOrder.indexOf(b))
				.map((action, idx) => (
					<div 
					data-testid={`action-${action}`}
					css={actionItemCss}>
						<Radio
							key={idx}
							label={labels.action[action]}
							checked={selectedAction === action}
							setChecked={onSelect}
							value={action}
						/>
						<Tooltip content={labels.tooltip[action]} isTopLayer placement={'top-start'}>
							<BnIcon icon={Icons.info} />
						</Tooltip>
					</div>
				))}
		</div>
	);
});

export const ActionSelect = memo(({ actions, onActionSelect = () => {}, explainer = 'Select how to issue this refund' }: ActionSelectProps) => {
	const [selectedAction, setSelectedAction] = useState<ArchiveSlotInvoiceActionEnum>();

	const handleSelect = (val: ArchiveSlotInvoiceActionEnum) => {
		setSelectedAction(val);
		onActionSelect(val);
	};

	return (
		<div css={optionContainerCss}>
			<Typography type={ETypography.body2Accented} color={ETypographyColor.secondary}>
				{explainer}
			</Typography>

			{actions && <ActionSelection selectedAction={selectedAction} actions={actions} onSelect={handleSelect} />}
		</div>
	);
});
