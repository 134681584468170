import { useRef, useState } from 'react';
import { useConfirmationAlert } from './useConfirmationAlert';

const ABORT_ERROR = 'AbortError';

/**
 * Represents the options for fetching data.
 *
 * @template T - The type of the data returned from the fetch.
 * @property onSuccess - A callback that is called on every successful api response with its data
 * @property onComplete - A callback that is called every time a request finished executing
 * @property onAbort - A callback that is called when the request is aborted
 * @property onTimeout - A callback that is called when a timeout was reached
 * @property onError - A callback that is called when ever a request resulted in an error (error response/any other error)
 * @property timeout - setting a timeout limit on the request
 * @property confirmationSettings.enabled - open a confirmation alert before initiating the action 
 * @property confirmationSettings.title - the confirmation title shown in the alert
 * @property confirmationSettings.message - the confirmation message shown in the alert
 * @property confirmationAbortSettings.enabled - open a confirmation alert before initiating the action 
 * @property confirmationAbortSettings.title - the confirmation title shown in the alert
 * @property confirmationAbortSettings.message - the confirmation message shown in the alert
 */
type TFetchOptions<T> = {
    onError?: (err) => void;
    onSuccess?: (data: T) => void;
    onComplete?: () => void;
    onAbort?: () => void;
    onTimeOut?: () => void;
    timeout?: number;
    confirmationSettings?: {
        enabled: boolean;
        title?: string
        message?: string;
    },
    confirmationAbortSettings?: {
        enabled: boolean;
        title?: string
        message?: string;
    }
};

/**
 * Represents a utility function for fetching data of a certain type
 * @template T - The type of data being received
 * @property isLoading - Indicator that a fetch request is in progress
 * @property abort - Function to abort the fetch request
 * @property fetch - Function to execute a fetch request
 */
type TFetch<T> = {
    isLoading: boolean;
    fetch?: () => void;
    abort?: () => void;
};

/**
 * Custom hook for handling data post with options to handle loading state and confirmation modals.
 *
 * @template T - The type of the data being fetched
 * @param {function} fetcher - The function that performs the actual fetching, accepting options containing an AbortSignal
 * @param {Object} [options] - Optional options for the fetch, including timeout and callbacks
 * @returns {Object} - An object containing data, loading state, fetch and abort functions, and first fetch status
 */
export const usePostData = <T>(
    fetcher: (options: { signal: AbortSignal }) => Promise<T>,
    options?: TFetchOptions<T>
): TFetch<T> => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const controller = useRef<AbortController>();

    const { setConfirmationAlert, closeAlert } = useConfirmationAlert();

    const fetch = async () => {
        let timeoutId: number;

        controller.current = new AbortController();

        setIsLoading(true);

        if (options?.timeout) {
            timeoutId = window.setTimeout(() => {
                setIsLoading(false);

                controller.current.abort();
                options?.onTimeOut?.();
            }, options.timeout);
        }

        
        fetcher({ signal: controller.current.signal }).then((
            response
        ) => {
            if (!response) {
                return;
            }

            if ((response as any).err) {
                throw response;
            }

            options?.onSuccess?.(response);
        }).catch((error) => {
            if (error.name === ABORT_ERROR) {
                options?.onAbort?.();
                return;
            }

            options?.onError?.(error);

        }).finally(() => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setIsLoading(false);
            closeAlert();
            options?.onComplete?.();
        });
    }

    const abort = () => {
        if (options?.confirmationAbortSettings?.enabled) {
            setConfirmationAlert({
                title: options?.confirmationAbortSettings?.title,
                message: options?.confirmationAbortSettings?.message,
                onConfirmFunc: () => controller.current?.abort(ABORT_ERROR),
            })
        } else {
            controller.current?.abort(Error);
        }
    }
    const wrappedFetch = async () => {
        if (!isLoading) {
            if (options?.confirmationSettings?.enabled) {
                setConfirmationAlert({
                    title: options?.confirmationSettings?.title,
                    message: options?.confirmationSettings?.message,
                    onConfirmFunc: fetch
                })
            } else {
                fetch();
            }
        }
    }

    return {
        isLoading,
        fetch: wrappedFetch,
        abort,
    };
};
