import { ICalendarPropState, ICalendarStateUpdate } from '@app/react/types/calendar';
import { DraftSlotDto, MaintenanceDto, Slot, TimeSlotDto, MaintenanceTimingEnum } from '@bondsports/types';
import { ECalendarView } from '@bondsports/utils';
import * as dayjs from 'dayjs';
import { cloneDeep, minBy, sortBy } from 'lodash';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useCallback, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilCallback } from 'recoil';
import { TranslationEn } from '../../../assets/i18n/en';
import { ISlotEventsToSpaces } from '../components/calendar/calendatUtils/bundleSlots';
import { bookingV2Api } from '../lib/api/bookingv2';
import { facilityApi } from '../lib/api/facilityApi';
import { organizationApi } from '../lib/api/organizationApi';
import { localStorage } from '../lib/storage';
import { ENotificationType } from '../stores/baseStore';
import { calendarStore, ECalendarMode, IGroups } from '../stores/calendarStore';
import { EStorageKeys } from '../types/enums';
import { ERoutePaths } from '../types/navigation';
import { SlotDto } from '../types/NewReservation';
import { IEventDates } from '../types/program';
import { EDateTimeFormats, EDurations } from '../types/times';
import { useColorCode } from './useColorCode';
import { eventDispatcher, EventsDispatcher } from './useEventDispatcher';
import { useNavigation } from './useNavigation';
import { useNotification } from './useNotification';
import { useOrganization } from './useOrganization';
import { useQuery } from './useQuery';
import { CONFLICTS_QUERY_KEYS } from '../lib/urlParams';
import { DateInput, DateTimeFormats, getCombinedDateTimeString, TimeUnit } from '@bondsports/date-time';
import { Resources } from '@app/react/types/packege';
import { isErrorResponse } from '@app/react/lib/network';
import { calculateOverallEndTime, calculateOverallStartTime, END_OF_DAY, MIDNIGHT } from '@bondsports/utils';
import { ISlot } from '@bondsports/utils/src/organisms/bo_calendar_slot/types';
import usePermissions from '@app/react/hooks/permissions/usePermissions';
import { IDraggingOptions } from '@bondsports/utils';

dayjs.extend(isoWeek);

const BEFORE_AND_AFTER_MAINTENANCE = [MaintenanceTimingEnum.BEFORE, MaintenanceTimingEnum.AFTER];

/**
 * calculate overall times for a slot (overallStartTime, overallEndTime)
 * @param slot - slot to calculate overall times for
 * @param currentDate - current date
 * @returns slot with overall times
 */
function calculateOverallTimes(
	slot: ISlot | DraftSlotDto | SlotDto | MaintenanceDto,
	currentDate: DateInput
): ISlot | DraftSlotDto | SlotDto | MaintenanceDto {
	slot = slot as ISlot;

	// if the parent slot is a day after the current date, we only need to calculate the start time of the maintenance slot
	// the only way to get this parent slot is if one of the maintenance slots happens at the current date
	if (slot.startDate > currentDate) {
		const earliestStart: string = minBy(
			slot.children?.map(child => child.startTime),
			time => time
		);

		slot.overallStartTime = earliestStart ?? MIDNIGHT;
		slot.overallEndTime = END_OF_DAY;

		return slot;
	}

	// ignore maintenance slots at the beginning and end of a slot as they do not affect the slot overall time
	const children =
		slot.children?.filter(child => BEFORE_AND_AFTER_MAINTENANCE.includes(Number(child.maintenanceTiming))) ?? [];

	const startTimes: string[] = [
		getCombinedDateTimeString(slot.startDate, slot.startTime),
		...children.map((child: ISlot) => getCombinedDateTimeString(child.startDate, child.startTime)),
	];

	const endTimes: string[] = [
		getCombinedDateTimeString(slot.endDate, slot.endTime),
		...children.map((child: ISlot) => getCombinedDateTimeString(child.endDate, child.endTime)),
	];

	// calculate the earliest start time slot + maintenance
	slot.overallStartTime = calculateOverallStartTime(startTimes, slot.startDate, currentDate);

	// calculate the latest end time slot + maintenance
	slot.overallEndTime = calculateOverallEndTime(endTimes, slot.endDate, currentDate);

	return slot;
}

const listToTree = (arr = []) => {
	let map = {},
		node,
		res = [],
		i;
	for (i = 0; i < arr.length; i += 1) {
		map[arr[i].id] = i;
		arr[i].children = [];
	}
	for (i = 0; i < arr.length; i += 1) {
		node = arr[i];
		if (node.parentResourceId) {
			if (arr[map[node.parentResourceId]]) {
				arr[map[node.parentResourceId]].children.push(node);
			}
		} else {
			res.push(node);
		}
	}

	return res;
};

export function toTree(data) {
	const arr = data.map(x => {
		const newX = cloneDeep(x);
		newX.startDate = dayjs(newX.startDate).format(EDateTimeFormats.YYYY_MM_DD);
		newX.endDate = dayjs(newX.endDate).format(EDateTimeFormats.YYYY_MM_DD);
		newX.children = [];
		return newX;
	});

	return listToTree(arr);
}

export interface ITimeSlot extends TimeSlotDto {
	relevantSlotId?: number | string;
	isStep4?: boolean;
	isFullpage?: boolean;
	resourceId: number;
}

export interface IReservations {
	name: string;
}

export interface ISlotsEvents {
	[eventId: number]: Slot;
}

type SlotUpdateInput = {
	endDate: string;
	endTime: string;
	overId: string;
	parentId: string;
	slotId: string;
	startDate: string;
	startTime: string;
	differenceInMinutes: number;
};

export const useCalendar = (disableRefetchOnDateChange = false) => {
	const [calendarKey, setCalendarKey] = useRecoilState<number>(calendarStore.calendarKey);
	const [calendarPropState, setCalendarPropState] = useRecoilState<ICalendarPropState>(calendarStore.calendarPropState);
	const [slots, setSlots] = useRecoilState(calendarStore.slots);
	const [draftSlots, setDraftSlots] = useRecoilState(calendarStore.draftSlots);
	const [resources, setResources] = useRecoilState(calendarStore.resources);
	const [facilities, setFacilities] = useRecoilState(calendarStore.facilities);
	const [selectedFacility, setSelectedFacility] = useRecoilState(calendarStore.selectedFacility);
	const [selectedFacilityFullObject, setselectedFacilityFullObject] = useRecoilState(
		calendarStore.selectedFacilityFullObject
	);
	const [CalendarScrollTo, setCalendarScrollTo] = useRecoilState(calendarStore.scrollTo);
	const prevFacility = useRef<number>(null);
	const prevMode = useRef<ECalendarMode>(null);
	const [selectedDate, setSelectedDate] = useRecoilState(calendarStore.selectedDate);
	const { organizationId, settings } = useOrganization();
	const { checkMultiplePermissions } = usePermissions();
	const [spaces, setSpaces] = useRecoilState(calendarStore.spaces);
	const [showSpaces, setShowSpaces] = useRecoilState(calendarStore.showSpaces);
	const [mode, setMode] = useRecoilState(calendarStore.mode);
	const [view, setView] = useRecoilState(calendarStore.view);
	const [isFilters, setFilters] = useRecoilState(calendarStore.isFilters);
	const [filters, setFiltersValues] = useRecoilState(calendarStore.filters);
	const [groups, setGroups] = useRecoilState(calendarStore.groups);
	const [filterResources] = useRecoilState(calendarStore.filterResources);
	const [dateRange, setDateRange] = useRecoilState(calendarStore.dateRange);
	const [selectedMonthlyResource, setSelectedMonthlyResource] = useRecoilState(calendarStore.selectedMonthlyResource);
	const [isLoading, setLoading] = useRecoilState(calendarStore.isLoading);
	const [isFirstRender, setFirstRender] = useRecoilState(calendarStore.isFirstRender);
	const [groupedResources, setGroupedResources] = useRecoilState(calendarStore.groupedResources);
	const [selectedGroup, setSelectedGroup] = useRecoilState(calendarStore.selectedGroup);
	const [scrollPosition, setScrollPosition] = useRecoilState(calendarStore.scrollPosition);

	const { ngNavigate } = useNavigation();
	const { getQueryString, setMultipleQuery, resetQuery, getQueryValue, removeMultipleQuery } = useQuery();
	const { setPopupNotification } = useNotification();
	const { getDefaultMaintenanceColor } = useColorCode();
	const defaultMaintColors = getDefaultMaintenanceColor();
	const labels = TranslationEn.calendar;
	const { YYYY_MM_DD } = EDateTimeFormats;

	const getLatestCalendarState = useRecoilCallback(({ snapshot }) => {
		return async () => {
			return await snapshot.getPromise(calendarStore.calendarPropState);
		};
	});

	const handleSetScrollPosition = (sp: { scrollTop: number; scrollLeft: number }) => {
		setScrollPosition(sp);
	};

	const removeDraftSlots = () => {
		const resetValue = { events: {}, eventsToSpaces: {} };
		setDraftSlots(resetValue);
		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces,
			slots,
			selectedDate,
			filters,
			groups,
			mode,
			view,
			selectedMonthlyResource,
			draftSlots: resetValue,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	/*
	const options = useMemo(() => {
		return {
			dragAndDrop: true,
			resize: false,
			infiniteScrolling: false,
			mode: mode,
			view: view,
			date: selectedDate,
			isLoading: isLoading,
			scrollTo: CalendarScrollTo,
			hourSize: {
				vertical: 90,
				horizontal: 90,
			},
			addNewSlotButton: {
				hoverDelay: '0.3s',
				duration: settings.autopopulateDuration,
				step: 30,
			},
			scrollPosition: scrollPosition,
		};
	}, [mode, view, selectedDate, isLoading, CalendarScrollTo, settings, scrollPosition]);
*/

	const handleSetSelectedMonthlyResource = (val: number) => {
		setSelectedMonthlyResource(val);
		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces,
			slots,
			selectedDate,
			filters,
			groups,
			mode: ECalendarMode.MONTHLY,
			view,
			selectedMonthlyResource: val,
			draftSlots,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	const isDailyOrMonthly = val => [ECalendarMode.DAILY, ECalendarMode.MONTHLY].includes(val);

	const handleSetMode = (val: ECalendarMode) => {
		setMode(val);

		// monthly support only vertical view
		const newView = val === ECalendarMode.MONTHLY ? ECalendarView.VERTICAL : view;
		setView(newView);
		setSelectedMonthlyResource(0);
		if (isDailyOrMonthly(val)) {
			handleUpdateCalendarPropState({
				isLoading: false,
				spaces,
				showSpaces,
				slots,
				selectedDate,
				filters,
				groups,
				mode: val,
				view: newView,
				selectedMonthlyResource: 0,
				draftSlots,
				groupedResources,
				resources,
				scrollPosition,
			});
		}
		fetchSpacesByDay(null, null, null, null, null, null, newView, val);
	};

	const handleSetView = (val: ECalendarView) => {
		setView(val);
		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces,
			slots,
			selectedDate,
			filters,
			groups,
			mode,
			view: val,
			selectedMonthlyResource,
			draftSlots,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	const handleSetFilters = (val: { [p: string]: string[] | number[] }) => {
		setFiltersValues(val);
		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces,
			slots,
			selectedDate,
			filters: val,
			groups,
			mode,
			view,
			selectedMonthlyResource,
			draftSlots,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	const createInitialOptions = (clonedState: ICalendarStateUpdate) => {
		const [canEditReservation, canEditProgram] = checkMultiplePermissions([
			permissions.rentals.reservations.edit,
			permissions.programs.sessions.events.edit,
		]);
		// build options
		return {
			dragAndDrop: clonedState.mode === ECalendarMode.DAILY,
			resize: false,
			infiniteScrolling: false,
			mode: clonedState.mode,
			view: clonedState.view,
			date: clonedState.selectedDate,
			isLoading: clonedState.isLoading,
			scrollTo: CalendarScrollTo,
			hourSize: {
				vertical: 90,
				horizontal: 90,
			},
			addNewSlotButton: {
				hoverDelay: '0.3s',
				duration: settings.autopopulateDuration,
				step: 30,
			},
			partialRendering: true,
			scrollPosition: clonedState.scrollPosition,
			draggingOptions: {
				canEditReservation,
				canEditProgram,
			}
		};
	};

	const createInitialMonthlyRes = (clonedState: ICalendarStateUpdate) => {
		// build selectedMonthlyResource
		return clonedState.mode === ECalendarMode.MONTHLY ? clonedState.selectedMonthlyResource : undefined;
	};

	const createInitialFilteredSpaces = (clonedState: ICalendarStateUpdate) => {
		// build filteredSpaces
		return clonedState.spaces
			.map(space => {
				if (
					clonedState.groupedResources.includes(space.id) &&
					clonedState.showSpaces[space.id] &&
					Object.keys(clonedState.groups).length > 0
				) {
					return {
						...space,
						children: space.children.filter(children => {
							return clonedState.showSpaces[space.id].includes(children.id);
						}),
					};
				} else {
					return space;
				}
			})
			.filter((space: { id: number; spaceType: string }) => {
				if (clonedState.filters) {
					let isRelevant = false;
					Object.keys(clonedState.filters).forEach((key: string) => {
						if ([...clonedState.filters[key]].includes(space[key])) {
							isRelevant = true;
						}
					});
					return isRelevant;
				} else {
					return space;
				}
			});
	};

	const createInitialEventsToSpaces = (clonedState: ICalendarStateUpdate) => {
		// build eventsToSpaces
		const initialEventsToSpaces = {};
		for (const key in clonedState.slots.eventsToSpaces) {
			initialEventsToSpaces[key] = clonedState.slots.eventsToSpaces[key];
			for (const draftSlotDates in clonedState.draftSlots.eventsToSpaces) {
				if (clonedState.draftSlots.eventsToSpaces[draftSlotDates]?.[key]) {
					initialEventsToSpaces[key] = initialEventsToSpaces[key].concat(
						clonedState.draftSlots.eventsToSpaces[draftSlotDates][key]
					);
				}
			}
		}
		return initialEventsToSpaces;
	};

	const createFinalSlots = (clonedState: ICalendarStateUpdate, initialEventsToSpaces: {}) => {
		// build finalSlots
		return {
			events: { ...clonedState.slots.events, ...clonedState.draftSlots.events[clonedState.selectedDate] },
			eventsToSpaces: initialEventsToSpaces,
		};
	};

	const refreshCalendar = (
		clonedState: ICalendarStateUpdate,
		initialOptions: {
			mode: ECalendarMode;
			date: string;
			isLoading: boolean;
			view: ECalendarView;
			addNewSlotButton: { duration: number; step: number; hoverDelay: string };
			hourSize: { horizontal: number; vertical: number };
			resize: boolean;
			infiniteScrolling: boolean;
			dragAndDrop: boolean;
			scrollTo: any;
			draggingOptions: IDraggingOptions;
		},
		initialSelectedMonthlyResource: number,
		initialFilteredSpaces: any[],
		initialFinalSlots: {
			eventsToSpaces: {};
			events: any;
		},
		resourcesCalendar: any[]
	) => {
		setCalendarPropState(prevState => ({
			...clonedState,
			options: initialOptions,
			selectedMonthlyResource: initialSelectedMonthlyResource,
			spaces: initialFilteredSpaces,
			finalSlots: initialFinalSlots,
			isLoading: false,
			key: 'calendar-key',
			scrollTo: CalendarScrollTo,
			resources: resourcesCalendar,
		}));
	};

	const handleUpdateCalendarPropState = (newState: ICalendarStateUpdate) => {
		const clonedState = cloneDeep(newState);

		const initialOptions = createInitialOptions(clonedState);

		const initialSelectedMonthlyResource = createInitialMonthlyRes(clonedState);

		const initialFilteredSpaces = createInitialFilteredSpaces(clonedState);

		const initialEventsToSpaces = createInitialEventsToSpaces(clonedState);

		const initialFinalSlots = createFinalSlots(clonedState, initialEventsToSpaces);

		refreshCalendar(
			clonedState,
			initialOptions,
			initialSelectedMonthlyResource,
			initialFilteredSpaces,
			initialFinalSlots,
			newState.resources
		);
	};

	const selectResourceGroup = (mainGroupId: number, childrenIds: number[]) => {
		const resourceGrp = { ...showSpaces, [mainGroupId]: childrenIds };
		setShowSpaces(resourceGrp);
		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces: resourceGrp,
			slots,
			selectedDate,
			filters,
			groups,
			mode,
			view,
			selectedMonthlyResource,
			draftSlots,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	const updateSlot = async (data: SlotUpdateInput) => {
		// find slot by id
		const rawSlot = slots.events[data.slotId];
		// fix for the jump of the calendar - basically we update here the state with the current change,
		// and then fetch the api again to validate the change
		const { events, eventsToSpaces } = slots;

		const newEvents = cloneDeep(events);
		const newEventsToSpaces = cloneDeep(eventsToSpaces);

		const currentEventIndex: number = newEventsToSpaces[rawSlot.spaceId].findIndex(
			event => Number(event.id) === Number(rawSlot.id)
		);

		const maintenanceExist: boolean = Boolean(
			newEventsToSpaces[rawSlot.spaceId]?.[currentEventIndex]?.children?.length
		);

		const slot = cloneDeep(newEvents[rawSlot.id]);

		for (const child of slot.children ?? []) {
			const childStartDate: dayjs.Dayjs = dayjs(getCombinedDateTimeString(child.startDate, child.startTime)).add(
				data.differenceInMinutes,
				TimeUnit.MINUTE
			);
			const childEndDate: dayjs.Dayjs = dayjs(getCombinedDateTimeString(child.endDate, child.endTime)).add(
				data.differenceInMinutes,
				TimeUnit.MINUTE
			);

			child.startDate = childStartDate.format(EDateTimeFormats.YYYY_MM_DD);
			child.startTime = childStartDate.format(EDateTimeFormats.H24_WITH_SECONDS);

			child.endDate = childEndDate.format(EDateTimeFormats.YYYY_MM_DD);
			child.endTime = childEndDate.format(EDateTimeFormats.H24_WITH_SECONDS);
		}

		const updatedEvent: ISlot = calculateOverallTimes(
			{
				...slot,
				startDate: data.startDate,
				endDate: data.endDate,
				startTime: data.startTime,
				endTime: data.endTime,
				spaceId: data.overId,
			},
			selectedDate
		) as ISlot;

		newEvents[rawSlot.id] = updatedEvent;

		if (rawSlot.spaceId === Number(data?.overId ?? 0)) {
			// stay on the same resource
			newEventsToSpaces[rawSlot.spaceId][currentEventIndex] = updatedEvent;
		} else {
			// move to another resource
			newEventsToSpaces[rawSlot.spaceId] = newEventsToSpaces[rawSlot.spaceId].filter(
				ev => Number(ev.id) !== Number(rawSlot.id)
			);

			newEventsToSpaces[data.overId].push(updatedEvent);
		}

		setSlots({
			events: newEvents,
			eventsToSpaces: newEventsToSpaces,
		});

		try {
			const startTime: string = dayjs(data.startTime, EDateTimeFormats.H24_WITH_MINUTES).format(
				EDateTimeFormats.H24_WITH_SECONDS
			);
			const endTime: string = dayjs(data.endTime, EDateTimeFormats.H24_WITH_MINUTES).format(
				EDateTimeFormats.H24_WITH_SECONDS
			);
			const startDate: string = data.startDate;
			const endDate: string = data.endDate;

			const response = await bookingV2Api.updatePartialSingleSlot(
				rawSlot.reservationId,
				organizationId,
				selectedFacility,
				rawSlot.id,
				{
					...rawSlot,
					startTime,
					endTime,
					startDate,
					endDate,
					spaceId: data.overId,
				}
			);

			if (!response.err) {
				setPopupNotification(labels.notifications.eventUpdated, ENotificationType.success);

				fetchSpacesByDay(null, null, null, !maintenanceExist);

				eventDispatcher<EventsDispatcher.CALENDAR_SLOT_UPDATED>(EventsDispatcher.CALENDAR_SLOT_UPDATED, {
					slotId: rawSlot.id,
				});
			} else {
				handleUpdateSlotError();
			}
		} catch (error) {
			console.log(error);
			handleUpdateSlotError();
		}
	};

	const fetchGroups = async (
		orgId?: number,
		facilityId?: number
	): Promise<{ groupsTree: IGroups; groupsParents: any[] }> => {
		const res = await facilityApi.getGroupsByFacilityId(orgId || organizationId, facilityId || selectedFacility);
		if (res.data) {
			const groupsTree = {};
			const groupsParents = [];
			res.data.forEach(group => {
				if (groupsTree[group.parentSlotId]) {
					groupsTree[group.parentSlotId] = [
						...groupsTree[group.parentSlotId],
						{
							name: group.name,
							childrenSlotIds: [...group.childrenSlotIds],
						},
					];
				} else {
					groupsParents.push(group.parentSlotId);
					groupsTree[group.parentSlotId] = [
						{
							name: group.name,
							childrenSlotIds: [...group.childrenSlotIds],
						},
					];
				}
			});

			Object.keys(groupsTree).forEach(key => {
				groupsTree[key] = [{ name: 'main', childrenSlotIds: [Number(key)] }, ...groupsTree[key]];
			});

			setGroupedResources(groupsParents);
			setGroups(groupsTree);
			return { groupsTree, groupsParents };
		}
	};

	const fetchFacilities = async (orgId: number) => {
		setLoading(true);
		const res = await organizationApi.getFacilitiesDepricated(orgId);
		setFacilities(res.data);
		if (selectedFacility === 0) {
			selectFacility(res.data?.[0], false);
		}
		const facility = selectedFacility || res.data?.[0].id;
		const { groupsTree, groupsParents } = await fetchGroups(orgId, facility);
		await fetchSpacesByDay(orgId, facility, null, null, groupsTree, groupsParents);
	};

	const removeDateFromStorge = () => localStorage.removeItem(EStorageKeys.EVENT_DATE_OBJECT);

	const handleSelectDate = async (v: string) => {
		removeDateFromStorge();
		setSelectedDate(v);
		if (!disableRefetchOnDateChange && selectedFacility !== 0) {
			return await fetchSpacesByDay(null, null, v, false);
		}
	};

	const nextDay = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.DAY).format(YYYY_MM_DD));
	};

	const previousDay = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.DAY).format(YYYY_MM_DD));
	};

	const nextWeek = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.WEEK).format(YYYY_MM_DD));
	};

	const previousWeek = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.WEEK).format(YYYY_MM_DD));
	};

	const nextMonth = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).add(1, EDurations.MONTH).format(YYYY_MM_DD));
	};

	const previousMonth = () => {
		handleSelectDate(dayjs(selectedDate, YYYY_MM_DD).subtract(1, EDurations.MONTH).format(YYYY_MM_DD));
	};

	const handleNextDateButton = () => {
		if (mode === ECalendarMode.DAILY) {
			nextDay();
		}
		if (mode === ECalendarMode.MONTHLY) {
			nextMonth();
		}
		if (mode === ECalendarMode.WEEKLY) {
			nextWeek();
		}
	};

	const handlePreviousDateButton = () => {
		if (mode === ECalendarMode.DAILY) {
			previousDay();
		}
		if (mode === ECalendarMode.MONTHLY) {
			previousMonth();
		}
		if (mode === ECalendarMode.WEEKLY) {
			previousWeek();
		}
	};

	const selectFacility = async (facility: any, isFetchSpacesByDay = true) => {
		//TODO: remove
		setSelectedFacility(facility.id);
		setselectedFacilityFullObject(facility);
		if (isFetchSpacesByDay) {
			const { groupsTree, groupsParents } = await fetchGroups(organizationId, facility.id);
			await fetchSpacesByDay(organizationId, facility.id, null, null, groupsTree, groupsParents);
		}
	};

	const fetchSpacesByDay = useCallback(
		async (
			orgId?: number,
			selectedFacilityId?: number,
			newDate?: string,
			isSkipLoading = false,
			groupsCalendar?: IGroups,
			groupsParents?: any[],
			viewVal?: ECalendarView,
			modeVal?: ECalendarMode
		) => {
			if (!isSkipLoading) {
				setLoading(true);
			}

			const date: string =
				newDate ??
				(localStorage.getItem(EStorageKeys.EVENT_DATE_OBJECT) as IEventDates)?.formattedStartDate ??
				selectedDate;

			const facilityId: number =
				selectedFacilityId || selectedFacility || +localStorage.getItem(EStorageKeys.FACILITYID);

			// Prevent fetching spaces if no facility
			if (!facilityId) {
				setLoading(false);
				return;
			}

			const res = await facilityApi.getSpacesByDay(orgId || organizationId, facilityId, date, modeVal ?? mode);

			if (isErrorResponse(res)) {
				setPopupNotification(labels.errors.failedToFetchCalendar, ENotificationType.warning);
				setLoading(false);
				return;
			}

			const newSpaces = toTree(res);

			// check if groups exist
			const showData = {};
			if (isFirstRender || prevFacility.current !== selectedFacility || prevMode.current === ECalendarMode.MONTHLY) {
				setFirstRender(false);
				prevFacility.current = selectedFacility;
				// initiate all spaces to be close at the first run
				newSpaces.forEach(space => {
					showData[space.id] = [space.id];
				});

				setShowSpaces(showData);
			}
			prevMode.current = mode;
			setSpaces(newSpaces);
			let newSlots = [];

			const eventsToSpaces: ISlotEventsToSpaces = {};
			const events = {} as ISlotsEvents;

			setResources(res as unknown as Resources[]);

			res.forEach(resource => {
				const slots = cloneDeep(resource.slots);

				newSlots = [...newSlots, ...slots];

				for (const slot of slots) {
					slot.children.forEach(child => (child.colorCode = defaultMaintColors));
					calculateOverallTimes(slot, selectedDate);
				}

				if (eventsToSpaces[resource.id]) {
					eventsToSpaces[resource.id] = [...eventsToSpaces[resource.id], ...slots];
				} else {
					eventsToSpaces[resource.id] = [...slots];
				}
			});

			newSlots.forEach(event => {
				events[event.id] = event;
			});

			setSlots({ events, eventsToSpaces: eventsToSpaces });

			if (!isSkipLoading) {
				handleUpdateCalendarPropState({
					isLoading: false,
					spaces: newSpaces,
					showSpaces: Object.keys(showData).length > 0 ? showData : showSpaces,
					slots: { events, eventsToSpaces: eventsToSpaces },
					selectedDate: date,
					filters,
					groups: Boolean(groupsCalendar) ? groupsCalendar : groups,
					mode: modeVal ?? mode,
					view: viewVal ?? view,
					selectedMonthlyResource,
					draftSlots: { events: {}, eventsToSpaces: {} },
					groupedResources: Boolean(groupsParents) ? groupsParents : groupedResources,
					resources: res,
					scrollPosition,
				});
			}

			setLoading(false);
		},
		[
			selectedDate,
			organizationId,
			selectedFacility,
			prevMode.current,
			prevFacility.current,
			mode,
			view,
			groupedResources,
			groups,
			showSpaces,
		]
	);

	const handleUpdateSlotError = () => {
		setPopupNotification(labels.errors.eventUpdate, ENotificationType.warning);
		fetchSpacesByDay(organizationId, selectedFacility);
	};
	const handleDateRangeChange = (newDateRange: { startDate: string; endDate: string }) => {
		setDateRange(newDateRange);
	};

	const updateDraftSlots = async (newSlots: (SlotDto | DraftSlotDto)[]) => {
		const sortedSlots: (SlotDto | DraftSlotDto)[] = sortBy(newSlots, s => s.startDate);
		const firstSlot: SlotDto | DraftSlotDto = sortedSlots.at(0);

		const maintenanceSlots = newSlots
			.filter(newSlot => newSlot.maintenanceSlots?.length)
			.flatMap(newSlot => newSlot.maintenanceSlots as MaintenanceDto[]);

		const allSlots = [newSlots, maintenanceSlots].flat().map(slot => calculateOverallTimes(slot, selectedDate));

		const now: number = Date.now();
		const { events, eventsToSpacesByDate } = allSlots.reduce(
			(maps, slot, index) => {
				const events = maps.events;
				const eventsToSpacesByDate = maps.eventsToSpacesByDate;

				const { startDate, spaceId } = slot as DraftSlotDto | SlotDto;
				const id: number = Number(`${now}${index}`);

				events[startDate] ??= {};

				events[startDate][id] = { ...slot, id, isDraft: true };

				eventsToSpacesByDate[startDate] ??= {};
				eventsToSpacesByDate[startDate][spaceId] ??= [];

				eventsToSpacesByDate[startDate][spaceId].push({ ...slot, id, isDraft: true });

				return maps;
			},
			{ events: {}, eventsToSpacesByDate: {} }
		);

		// getting the latest calendar state to update the draft slots without losing any data
		const latestState: ICalendarPropState = await getLatestCalendarState();
		if (firstSlot) {
			setCalendarScrollTo({
				startTime: firstSlot.startTime,
				endTime: firstSlot.endTime,
				resourceId: firstSlot.spaceId,
				trigger: now,
			});
		}

		setDraftSlots({ events, eventsToSpaces: eventsToSpacesByDate });
		handleUpdateCalendarPropState({
			...latestState,
			isLoading: false,
			draftSlots: { events, eventsToSpaces: eventsToSpacesByDate },
		});
	};

	const handleCloseConflicts = (isFromSchedule = false, refetchCalendar = true) => {
		const keysToRemove: string[] = [
			CONFLICTS_QUERY_KEYS.CONFLICTS,
			CONFLICTS_QUERY_KEYS.RESOURCE_ID,
			CONFLICTS_QUERY_KEYS.START_TIME,
			CONFLICTS_QUERY_KEYS.END_TIME,
			CONFLICTS_QUERY_KEYS.START_DATE,
			CONFLICTS_QUERY_KEYS.END_DATE,
			CONFLICTS_QUERY_KEYS.RESERVATION_SLOT_ID,
			CONFLICTS_QUERY_KEYS.CINLINE,
			CONFLICTS_QUERY_KEYS.FULL_PAGE,
		];

		if (isFromSchedule) {
			const path = localStorage.getItem(EStorageKeys.FORMATTED_PATH) as string;
			ngNavigate(ERoutePaths.ACTIVITIES, path);
		} else {
			removeMultipleQuery(keysToRemove);
			if (refetchCalendar) {
				eventDispatcher<EventsDispatcher.CALENDAR_SLOT_UPDATED>(EventsDispatcher.CALENDAR_SLOT_UPDATED);
			}
		}
	};

	/**
	 *
	 * @param resourceId - the id of the relevant resource
	 * @param startTime - HH:mm format
	 * @param endTime - HH:mm format
	 * @param startDate - YYYY-MM-DD format
	 * @param endDate - YYYY-MM-DD format
	 */
	const handleNavigateToConflicts = ({
		resourceId,
		startTime,
		endTime,
		startDate,
		endDate,
		relevantSlotId,
		isStep4 = false,
		isFullpage = false,
	}: ITimeSlot) => {
		if (window.location.href.includes(ERoutePaths.CALENDAR)) {
			setMultipleQuery({
				conflicts: 'true',
				r: String(resourceId),
				st: startTime,
				et: endTime,
				sd: startDate,
				ed: endDate,
				rsid: relevantSlotId + '',
				cinline: isStep4 ? 'false' : 'true',
				fullpage: String(isFullpage),
			});
		} else {
			const { formattedStartDate, formattedEndDate } = localStorage.getItem(
				EStorageKeys.EVENT_DATE_OBJECT
			) as IEventDates;
			ngNavigate(
				ERoutePaths.CALENDAR,
				`?${getQueryString({
					conflicts: 'true',
					cinline: 'true',
					r: String(resourceId),
					st: startTime,
					et: endTime,
					sd: formattedStartDate,
					ed: formattedEndDate,
					rsid: relevantSlotId + '',
					fullpage: String(isFullpage),
				})}`
			);
		}
	};

	const resetDraftSlots = () => {
		const emptyDrafts = { events: {}, eventsToSpaces: {} };
		setDraftSlots(emptyDrafts);

		handleUpdateCalendarPropState({
			isLoading: false,
			spaces,
			showSpaces,
			slots,
			selectedDate,
			filters,
			groups,
			mode,
			view,
			selectedMonthlyResource,
			draftSlots: emptyDrafts,
			groupedResources,
			resources,
			scrollPosition,
		});
	};

	const weeklyRange = useMemo(() => {
		const start = dayjs(selectedDate).isoWeekday(1).format(DateTimeFormats.YYYY_MM_DD);
		const end = dayjs(selectedDate).isoWeekday(7).format(DateTimeFormats.YYYY_MM_DD);
		return {
			rangeStart: start,
			rangeEnd: end,
		};
	}, [selectedDate]);

	return {
		resources,
		setSlots,
		slots,
		facilities,
		fetchFacilities,
		selectedFacility,
		selectFacility,
		handleSelectDate,
		fetchSpacesByDay,
		groupedResources,
		selectedDate,
		spaces,
		mode,
		isFilters,
		setFilters,
		filters,
		setFiltersValues,
		handleSetFilters,
		fetchGroups,
		groups,
		filterResources,
		handleCloseConflicts,
		selectResourceGroup,
		showSpaces,
		view,
		setMode,
		handleSetMode,
		setView,
		handleSetView,
		dateRange,
		weeklyRange,
		handleDateRangeChange,
		CalendarScrollTo,
		setCalendarScrollTo,
		handleNextDateButton,
		handlePreviousDateButton,
		selectedMonthlyResource,
		setSelectedMonthlyResource,
		handleSetSelectedMonthlyResource,
		isLoading,
		updateDraftSlots,
		setShowSpaces,
		updateSlot,
		draftSlots,
		setDraftSlots,
		handleNavigateToConflicts,
		resetDraftSlots,
		calendarPropState,
		handleUpdateCalendarPropState,
		calendarKey,
		removeDraftSlots,
		selectedGroup,
		setSelectedGroup,
		handleSetScrollPosition,
		scrollPosition,
		selectedFacilityFullObject,
	};
};

const permissions = {
	programs: {
		sessions: {
			events: {
				edit: ['programs.sessions.events.edit'],
			},
		},
	},
	rentals: {
		reservations: {
			edit: ['calendar.reservation.edit'],
		},
	},
}

