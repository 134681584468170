import {
	CustomerFamilyStatusEnum,
	EmergencyContact,
	FuturePaymentStatusEnum,
	Invoice,
	OrderByEnum,
	OrganizationUsers,
	Payment,
	PaymentStatusEnum,
	User,
} from '@bondsports/types';
import { IOption } from '@bondsports/utils';
import { RCAddress } from '@rcenter/core';

export enum EStatusColorMapper {
	family = 'purple',
	user = 'yellow',
	employee = 'green',
	organization = 'red',
	primary = 'yellow',
	dependent = 'pink',
	independent = 'blue',
	not_paid = 'red',
	paid = 'green',
	void = 'gray',
	partial = 'yellow',
	future = 'blue',
	succeeded = 'green',
	canceled = 'purple',
	scheduled = 'blue',
	charged = 'green',
	male = 'blue',
	female = 'purple',
	other = 'yellow',
	failed = 'red',
	retry_in_progress = 'red',
	active = 'green',
	inactive = 'orange',
}
interface IIsActive {
	active: true;
}
interface INotificationSettings {
	feed: IIsActive;
	teams: IIsActive;
	users: IIsActive;
	events: IIsActive;
	venues: IIsActive;
	leagues: IIsActive;
}

//TBD move to @bondsports/types SHOULD renamed to CustomerEntityTypeEnum
export enum CustomerTypeEnum {
	USER = 'user',
	EMPLOYEE = 'employee',
	FAMILY = 'family',
	ORGANIZATION = 'organization',
}

export const CustomerTypeFilterOptions = Object.values(CustomerTypeEnum).filter((type) => type !== CustomerTypeEnum.EMPLOYEE);

export enum ResourceNameTypeEnum {
	EVENT = 'event' as any,
	VENUE = 'venue' as any,
	TEAM = 'team' as any,
	LEAGUE = 'league' as any,
	USER = 'user' as any,
	ORGANIZATION = 'organization' as any,
	APP = 'app' as any,
	FEED = 'feed' as any,
	MATCH = 'match' as any,
	ROUND = 'round' as any,
	PORTAL = 'portal' as any,
	SEASON = 'season' as any, // league season
	TOURNAMENT = 'tournament' as any,
	MEMBERSHIP = 'membership' as any,
	DIVISION = 'division' as any,
	GAMESLOT = 'gameslot' as any,
	SPACE = 'space' as any,
	RESERVATION = 'reservation' as any,
	INVOICE = 'invoice' as any,
	CUSTOMER = 'customer' as any,
	PACKAGE = 'package' as any,
	PROGRAM = 'program' as any,
	PROGRAM_SEASON = 'program_season' as any,
}

export interface IFamilyAccount {
	createdAt: string;
	deletedAt: string;
	familyAccountId: number;
	id: number;
	isAdmin: boolean;
	updatedAt: string;
	userId: string;
}

export interface ICustomer {
	id: number;
	name?: string;
	firstName: string;
	lastName: string;
	birthDate?: string;
	lastLogin: Date;
	gender: 1 | 2 | 3;
	entityType: CustomerTypeEnum;
	color?: string;
	phoneNumber?: string;
	mainMediaId?: number;
	creatorType: ResourceNameTypeEnum;
	organizationId?: number;
	entityId: number;
	vetted?: boolean;
	members?: ICustomer[];
	tags?: Tag[];
	//returning from API but not exist in the Yossi's Interfaces
	address: RCAddress;
	addressId: number;
	createAsId: number;
	createAsType: string;
	createdAt: string;
	creatorId: number;
	currentAddressId?: number;
	customerId?: number;
	deletedAt?: string;
	email: string;
	height?: string;
	lastInteractionDay: string;
	loginToken: string;
	merchantId?: number;
	motto?: string;
	notificationSettings: INotificationSettings;
	ownerId?: string;
	passwordResetExpires?: string;
	passwordResetToken?: string;
	privateProfile?: string;
	profilePicture?: IProfilePicture;
	profilePictureId: number;
	pushNotifications: boolean;
	status: TStatus;
	updatedAt: string;
	userCreatorId: number;
	weight?: string;
	about?: string;
	allowMultiSignHack?: string;
	waiverSignedDate?: string;
	// missing in the API-
	waiver: any;
	balance: number;
	messages: any;
	notes: any;
	emergencyContacts: EmergencyContact[];
	userInFamilyAccounts?: IFamilyAccount[];
	customer?: ICustomer; // Used on cart with adding family memberships
	storedCredit?: number;
	linkedAccounts?: number[];
	colorCodeId?: number;
	familyStatus?: CustomerFamilyStatusEnum;
	memberships: IMembershipStatus[];
	noteCount?: number;
	isAnonymous?: boolean;
	organizationUsers?: OrganizationUsers[];
	isEmployee?: boolean;
	//The parent User of this Customer.  Not fetched by default.
	user?: User;
}

interface IProfilePicture {
	fileType?: string;
	id?: number;
	isDefault?: boolean | null;
	mediaKey?: string;
	mediaType?: number;
	provider?: string;
	url?: string;
}

// waiver
// balance

export type TCustomer = ICustomer | null;

type TStatus = 'pending' | 'in process' | 'done';

interface Tag {
	id: number;
	value: string;
}

// TODO: this type should be imported from @bondsports/types once it's there
export interface IMembershipStatus {
	membershipId: number;
	membershipStatus: string;
	paymentStatus: PaymentStatusEnum;
}

export interface ICustomerResponse {
	data: ICustomer;
}

export interface IAccountType {
	entityType: CustomerTypeEnum;
	familyStatus?: CustomerFamilyStatusEnum;
}

export interface IMoreMenuProps {
	organizationId: number;
	customerId?: number;
	entityType: CustomerTypeEnum;
	isLinkedMember: boolean;
}

export interface ICustomerListRow {
	id: number;
	lastName: string;
	firstName: string;
	email: string;
	birthDate: string;
	isWaiverRelevant: boolean;
	memberships: IMembershipStatus[];
	accountType: IAccountType;
	more: IMoreMenuProps;
	entityType: CustomerTypeEnum;
}

export interface ICustomerListCulumns extends ICustomerListRow {
	members?: ICustomerListRow[];
}

export enum CustomerPageTabsEnum {
	CUSTOMER_DETAILS = 'customerDetails',
	OVERVIEW = 'overview',
	MEMBERSHIP = 'membership',
	INVOICES = 'invoices',
	PAYMENTS = 'payments',
	ACTIVITIES = 'activities',
	METHODS = 'methods',
}

export interface ILinkedAccount {
	customerId: number;
	customerName: string;
	isFamily: boolean;
	familyStatus?: CustomerFamilyStatusEnum;
}

export enum AlertsStatusEnum {
	GOOD_STANGING = 'goodStanding',
	ISSUES = 'issues',
	NO_DATA = 'noData',
	ERROR = 'error',
	N_A = 'n/a',
}

export enum PaymentAlertEnum {
	NOT_PAID = 'notPaid',
	FAILED = 'failed',
}
export interface IGetInvoicesByCustomerId {
	customerId: number;
	paymentStatusFilter?: PaymentStatusEnum[];
	methodFilter?: string[];
	searchFilter?: string;
	months?: string[];
	page?: number;
	itemsPerPage: number | string;
	orderByProperty?: (keyof Invoice | keyof Payment)[];
	statuses?: FuturePaymentStatusEnum[];
	sortOrder?: OrderByEnum[];
	isAlerts?: boolean;
}

export interface CustomerQuery {
	nameSearch?: string;
	page?: number;
	itemsPerPage?: number;
	customerTypes?: CustomerTypeEnum;
}

export interface CustomerOption extends IOption, ICustomer {}

export interface IAdminAndFamilyCustomers {
	admin?: ICustomer;
	family?: ICustomer;
}
