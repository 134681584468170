/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { FC, Fragment, useEffect, useState } from 'react';
import { Loader } from '../../../../components/Loader/index';
import { justifyCenterCss } from '../../../../organisms/refundPopup/style';
import { flexCenterCss, fullHeightCss, fullWidthCss } from '../../../../styles/utils';
import { spaceRowContainer } from '../../../styles';
import { IDailyViewProps } from '../../../types';
import { DayToDropHorizontal } from './DayToDropHorizontal';
import { HorizontalCurrentTimeLine } from './HorizontalCurrentTimeLine';
// import { useMiddlewareContext } from "../../../hooks/useMiddlewareContext";

export const DailyViewHorizontal: FC<IDailyViewProps> = ({
	spaces,
	EventComponent,
	onAddNewSlotClick,
	isModeChanged,
	isLoading,
	currentDate,
}) => {
	// const { partialRanges, options } = useMiddlewareContext();

	const [updates, setUpdates] = useState(false);

	// useEffect(() => {
	//   setUpdates(true);
	// }, [options.hourSize]);

	useEffect(() => {
		if (updates) setUpdates(false);
	}, [updates]);

	if (updates) return null;

	return (
		<Fragment>
			{isLoading ? (
				<div css={[flexCenterCss, justifyCenterCss, fullWidthCss, fullHeightCss]}>
					<Loader width={60} />
				</div>
			) : (
				spaces.map((space, index) => {
					const hiddenEvents = false;

					if (space.children.length === 0) {
						return (
							<div
								key={`DailyViewHorizontal-horizontal-${space.id}`}
								css={spaceRowContainer()}
								data-aid="DailyViewHorizontal-horizontal"
							>
								{
									<DayToDropHorizontal
										key={`DailyViewHorizontal-${space.id}`}
										id={String(space.id)}
										EventComponent={EventComponent}
										onAddNewSlotClick={onAddNewSlotClick}
										hiddenEvents={hiddenEvents}
										isModeChanged={isModeChanged}
										currentDate={currentDate}
									/>
								}
							</div>
						);
					} else {
						return (
							<div key={`DailyViewHorizontal-horizontal-${space.id}`} css={spaceRowContainer()}>
								{space.children.map(item => {
									return (
										<DayToDropHorizontal
											key={`DailyViewHorizontal-${item.id}`}
											id={String(item.id)}
											EventComponent={EventComponent}
											onAddNewSlotClick={onAddNewSlotClick}
											hiddenEvents={hiddenEvents}
											isModeChanged={isModeChanged}
											currentDate={currentDate}
										/>
									);
								})}
							</div>
						);
					}
				})
			)}
			<HorizontalCurrentTimeLine />
		</Fragment>
	);
};
