/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { flexCol, gapCss } from '@app/react/styles/utils';
import { PauseDetails } from '../pauseDetails';
import { FormButtons } from '@app/react/components/shared/Buttons/FormButtons';
import { FormSaveButton } from '@app/react/components/shared/Buttons/SaveButton';
import { TranslationEn } from '@assets/i18n/en';
import { FormCancelButton } from '@app/react/components/shared/Buttons/CancelButton';
import { MembershipStatusEnum } from '@bondsports/types';

interface PauseFormProps {
	onClick: () => void;
	isEditMode?: boolean;
	handleReset: () => void;
	handlePauseHistoryClick: () => void;
	organizationId: number;
	showWarning: boolean;
	memberEndDate: string;
	currentStatus: MembershipStatusEnum;
}

export const PauseForm = ({ onClick, currentStatus, isEditMode, handlePauseHistoryClick, handleReset, organizationId, showWarning, memberEndDate }: PauseFormProps) => {
	
	return (
		<div css={[flexCol, gapCss(35)]} data-testid="pause-form-details">
			<PauseDetails onHistoryWarningClick={handlePauseHistoryClick} showWarning={showWarning} status={currentStatus} memberEndDate={memberEndDate} organizationId={organizationId} isEdit={isEditMode} />
			<FormButtons>
				<FormCancelButton onClick={handleReset} />
				<FormSaveButton type='button' label={TranslationEn.general.buttons.next} onClick={onClick}/>
			</FormButtons>
		</div>
	);
};
