/** @jsx jsx */

import { CARD_SUBTYPE_WITH_FEES } from '@app/react/components/payments/types/payment-method.const';
import { findFeeSelectedStripePaymentMethod } from '@app/react/lib/payment';
import { TranslationEn } from '@assets/i18n/en';
import {
	CalculateFeeResult,
	FeeDto,
	FuturePaymentStatusEnum,
	PaymentMethodTypeEnum,
	UserPaymentMethod,
	UserPaymentMethodOption,
} from '@bondsports/types';
import { BnIcon, ETypography, ETypographyColor, Icons, Pricify, Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { useLayout } from 'app/react/hooks/useLayout';
import { useReceipt } from 'app/react/hooks/useReceipt';
import { useStation } from 'app/react/hooks/useStation';
import { organizationApi } from 'app/react/lib/api/organizationApi';
import { Mixpanel, MixpanelEvents } from 'app/react/lib/mixpanel';
import { roundPriceCents } from 'app/react/lib/pricing';
import { ICustomer } from 'app/react/types/customers';
import { EChargeInnerStep, EChargeStatus, IPayment, TChargeSteps } from 'app/react/types/payment';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { IPaymentMethodData, TPrintDetails } from '../../../hooks/purchase/types';
import { usePurchase } from '../../../hooks/purchase/usePurchase';
import { useCart } from '../../../hooks/useCart';
import { paymentApi } from '../../../lib/api/paymentApi';
import { colors, colors_v2 } from '../../../styles/theme';
import { flex, flexCol, flexCss, gapCss, justifyAndAlignCenter, svgCss } from '../../../styles/utils';
import { Error } from '../Error';
import { Footer } from '../Footer';
import { AddSchedulePayment } from '../Schedule/addSchedulePayment';
import { ConfirmScheduled } from '../Schedule/confirmScheduled';
import { Reschedule } from '../Schedule/Reschedule';
import { SimpleButton } from '../utils';
import { getPaymentMethodLabelByValue } from '../utils/paymentMethods';
import { ACH } from './ACH';
import { CashOrDocument } from './CashOrDocument';
import { Completed } from './Completed';
import { CreditCard } from './CreditCard';
import { NewCard } from './newCard';
import { PaymentMethodsContext } from './PaymentMethodsProvider';
import { Scheduled } from './ScheduledPopup';
import { SelectMethod } from './selectMethod';
import { ApplyMethodCheckbox } from '@bondsports/utils';
import { cloneDeep, isEmpty } from 'lodash';
import { isErrorResponse } from '@app/react/lib/network';
import { IChargePartialOptions } from '../types/partialCharge.types';

const topCss = css`
	${flex};
	justify-content: center;

	border-bottom: 1px solid ${colors.borderSeperator};
	padding: 28px 32px 24px 32px;
`;

const topTextCss = css`
	${flexCol};
	gap: 12px;
	max-width: 70%;
	text-align: center;
	align-items: center;
`;

const backButtonCss = css`
	${flex};
	position: absolute;
	align-items: center;
	font-size: 1.2rem;
	line-height: 1.5rem;
	top: 0.75rem;
	left: 0.75rem;
`;

const iconCss = css`
	margin-right: 5px;

	div {
		justify-content: center;
		display: flex;
		align-items: center;
	}
`;

const topTextSectionCss = css`
	${flexCol};
	${gapCss(8)};
	align-items: center;
`;

const leftJustifyRowCss = css`
	${flexCss};
	${justifyAndAlignCenter};
	${gapCss(8)};
`;

const selectedMethodWidth = css`
	width: 210px;
	text-align: left;
`;

interface Props {
	toggle: () => void;
	totalAmount: number;
	userId: number;
	organizationId: number;
	invoiceId?: number;
	isAmountEditable?: boolean;
	alternativeHandleCharge?: (value: unknown) => Promise<void>;
	isScheduled?: boolean;
	initialStep?: TChargeSteps;
	initialLabels?: Record<string, string>;
	actuallyPaid?: number;
	alternativeHandlePrintReceipt?: () => void;
	alternativeHandleSendReceipt?: (contactMethod: string, sendAddress: string) => void;
	handleClose?: (removeReceipt?: boolean) => void;
	showApplyMethodCheckbox?: boolean;
	isApplyPaymentMethodDisabled?: boolean;
	customer?: ICustomer;
	showFeeWarning?: boolean;
	paymentMethods: UserPaymentMethodOption[];
	onSelectPaymentMethod?: (paymentMethod?: UserPaymentMethod, scheduledPaymentCount?: number) => void;
	onSetAmount?: (val: number) => void;
	feeAmount?: number;
	hasMembershipLine?: boolean;
	hasRollingMembershipLine?: boolean;
	originalPrice?: number;
	resetPricing?: () => void;
	scheduledPayments?: IPayment[];
	fees?: CalculateFeeResult[];
	isProcessing?: boolean;
	flowType?: FlowType;
	onBackButton?: (currentStep: TChargeSteps) => void;
}

const FULL_FEE_STEPS = [
	PaymentMethodTypeEnum.CARD,
	PaymentMethodTypeEnum.ACH,
	EChargeInnerStep.NEW_CARD,
	EChargeInnerStep.SWIPE,
	EChargeInnerStep.SCHEDULED_CHARGE,
];

export enum FlowType {
	SCHEDULED = 'SCHEDULED',
	DEFAULT = '',
}

const NEW_CARD = 'new-card';

export const Charge = ({
	toggle,
	totalAmount,
	userId,
	invoiceId,
	organizationId,
	isAmountEditable = true,
	alternativeHandleCharge,
	isScheduled = false,
	initialLabels,
	initialStep = '',
	actuallyPaid = -1,
	alternativeHandlePrintReceipt,
	alternativeHandleSendReceipt,
	customer,
	handleClose,
	showFeeWarning = false,
	paymentMethods,
	onSelectPaymentMethod,
	onSetAmount,
	hasRollingMembershipLine,
	feeAmount,
	originalPrice,
	resetPricing,
	scheduledPayments = [],
	showApplyMethodCheckbox = false,
	isApplyPaymentMethodDisabled = false,
	hasMembershipLine = false,
	fees = [],
	isProcessing,
	flowType = FlowType.DEFAULT,
	onBackButton,
}: Props) => {
	const labels = TranslationEn.payments;
	const paymentsInvoicesLabels = TranslationEn.customers.paymentsInvoices;
	const { handlePurchasePartial } = usePurchase();
	const [amount, setAmount] = useState<number>(roundPriceCents(originalPrice));
	const [method, setMethod] = useState<TChargeSteps>(initialStep);
	const [payments, setPayments] = useState<IPayment[]>(scheduledPayments);
	const [selectedCard, setSelectedCard] = useState('');
	const [selectedFullCard, setSelectedFullCard] = useState<UserPaymentMethod>();
	const [step, setStep] = useState<TChargeSteps>(initialStep);
	const [addScheduleState, setScheduleState] = useState<{ paymentMethod: PaymentMethodTypeEnum }>();
	const [loader, setLoader] = useState(false);
	const [prevStep, setPrevStep] = useState<TChargeSteps>(initialStep ?? '');
	const [errorMessage, setErrorMessage] = useState('');
	const { disabledScreenToggle } = useLayout();
	const [isAllMandatoryFieldsValidated, setIsAllMandatoryFieldsValidated] = useState(false);
	const [isApplyPaymentMethodCheckbox, setisApplyPaymentMethodCheckbox] = useState(false);
	const [triggerForNewCardSubmission, setNewCCTrigger] = useState(false);
	const [isTriggerSwipe, setIsTriggerSwipe] = useState(false);
	const [anonymous, setAnonymous] = useState<number>();
	const { connectToStation, shiftId } = useStation();
	const [printDetails, setPrintDetails] = useState<TPrintDetails>({
		customerId: 0,
		paymentId: 0,
	});
	const { handleSendReceipt, printReceipt } = useReceipt();
	const [documentNumber, setDocumentNumber] = useState('');
	const { creditCards, ACHMethods, setPaymentMethodsIfUserExists } = useContext(PaymentMethodsContext);
	const { getPaymentMethods } = useCart();
	const isCompleted = step === EChargeStatus.COMPLETED;
	const isError = step === EChargeStatus.ERROR;
	const shouldDisplayApplyCheckbox = hasRollingMembershipLine || showApplyMethodCheckbox;
	const [fee, setFee] = useState<FeeDto>();
	const isSwipeSelected = step === EChargeInnerStep.SWIPE;
	const isDisplayAmount =
		selectedCard?.includes('remove') || !selectedCard || isSwipeSelected || selectedCard?.includes('src');
	const totalBeforeSelectingACard = Pricify(Number(isDisplayAmount ? amount : totalAmount));
	const chargeButtonText =
		step === EChargeInnerStep.NEW_CARD
			? labels.newCardButton
			: `${paymentsInvoicesLabels.charge} ${totalBeforeSelectingACard}`;
	const totalAmountTitle = `${paymentsInvoicesLabels.total}: ${Pricify(totalAmount)}`;
	const [subtitle, setSubtitle] = useState<string>(initialLabels?.subTitle ?? totalAmountTitle);

	const secondSubtitle =
		fee && feeAmount && selectedCard && selectedCard !== NEW_CARD
			? `${TranslationEn.payments.subTitle.feeAmount} - ${Pricify(feeAmount)}`
			: '';
	const [mainTitle, setMainTitle] = useState(initialLabels?.title ?? labels.payment);

	const showTitle = useMemo(() => {
		switch (step) {
			case EChargeInnerStep.SCHEDULED_CHARGE:
				return !isScheduled || (isScheduled && initialStep === EChargeInnerStep.RESCHEDULE);
			case EChargeInnerStep.RESCHEDULE:
				return false;
			case EChargeInnerStep.SCHEDULED:
			default:
				return true;
		}
	}, [step]);

	const [customerClone, setCustomerClone] = useState<ICustomer>();

	useEffect(() => {
		if (!isEmpty(customer)) {
			setCustomerClone(cloneDeep(customer));
		}
	}, [customer]);

	useEffect(() => {
		if (initialStep !== EChargeInnerStep.NEW_CARD) setSubtitle(totalAmountTitle);
	}, [totalAmountTitle]);

	useEffect(() => {
		organizationApi.getAnonymousCustomer(organizationId).then(({ data }) => {
			setAnonymous(data.entityId);
		});
		connectToStation(organizationId);
	}, []);

	useEffect(() => {
		if (initialStep !== EChargeInnerStep.NEW_CARD) setPaymentMethodsIfUserExists();
	}, []);

	const setStepHandler = (step: TChargeSteps, isLoader = false) => {
		setStep(step);
		setLoader(isLoader);
	};

	const handleError = (message: string) => {
		setStepHandler(EChargeStatus.ERROR);
		setErrorMessage(message);
	};

	const handleCompleted = () => {
		setStepHandler(EChargeStatus.COMPLETED);
	};

	const handleChargeClick = (stepOverride?: string) => {
		const forceStep = typeof stepOverride === 'string' ? stepOverride : undefined;
		if (!loader) {
			setLoader(true);

			switch (forceStep ?? step) {
				case EChargeInnerStep.NEW_CARD:
					// Should be the logic of checkout after confirmation
					setNewCCTrigger(true);
					break;
				case EChargeInnerStep.SWIPE:
					setIsTriggerSwipe(true);
					break;
				case '':
					if (method === PaymentMethodTypeEnum.BALANCE) {
						paymentAction(selectedCard, method);
					} else {
						setStepHandler(method);
					}
					break;
				default:
					paymentAction(selectedCard, method);
					break;
			}
		}
	};

	const payPartialBalance = (paymentMethodData: IPaymentMethodData) => {
		const data = {
			invoiceId,
			amountToPay: roundPriceCents(Number(amount)),
			organizationId,
			payingUserId: userId || anonymous,
			paymentMethodData,
			shiftId,
		};
		handlePurchasePartial(data).then(response => {
			setLoader(false);
			disabledScreenToggle(false);

			if (isErrorResponse(response)) {
				return handleError(response.err);
			}

			switch (response.status) {
				case FuturePaymentStatusEnum.FAILED:
					handleClose();
					break;
				default: {
					const paymentId = response.invoice.lineItemHistory?.find(lih => lih.paymentId)?.paymentId;
					setPrintDetails({ customerId: response.payment.payingUserId, paymentId });
					handleCompleted();
					break;
				}
			}
		});
	};

	const paymentAction = (token: string, type: string) => {
		if (token.includes('remove')) {
			token = '';
		}

		if ([PaymentMethodTypeEnum.CHECK, PaymentMethodTypeEnum.GIFT_CARD].includes(type as PaymentMethodTypeEnum)) {
			token = documentNumber;
		}
		if (alternativeHandleCharge) {
			const applyPaymentMethodToFuture =
				shouldDisplayApplyCheckbox && !isApplyPaymentMethodDisabled ? isApplyPaymentMethodCheckbox : false;
			alternativeHandleCharge({
				token,
				type,
				applyPaymentMethodToFuture,
				isNewCard: selectedCard === NEW_CARD,
				amount,
			})
				.then(handleCompleted)
				.finally(() => setLoader(false));
		} else {
			disabledScreenToggle(true);
			// Was in the scheduled payment flow
			if (addScheduleState) {
				setStep(EChargeInnerStep.SCHEDULED);
			} else {
				// Regular payment flow
				payPartialBalance({ token, type, applyForFuture: isApplyPaymentMethodCheckbox });
			}
			disabledScreenToggle(false);
		}
	};

	const updateSubTitle = () => {
		setSubtitle(
			initialStep === EChargeInnerStep.NEW_CARD
				? ''
				: `${labels.subTitle.chargingAmount} - ${totalBeforeSelectingACard}`
		);
	};

	const handleDocumentNumber = (number: string) => {
		setDocumentNumber(number);
	};

	const setPaymentMethod = (paymentMethod: TChargeSteps) => {
		Mixpanel.track(MixpanelEvents.PICK_PAYMENT_METHOD, {
			cart_total_price: totalAmount,
			payment_method: paymentMethod,
		});
		setMethod(paymentMethod);
	};

	const handleTrackBackButton = () => {
		Mixpanel.track(MixpanelEvents.BACK_TO_PAYMENT_METHOD, {
			cart_total_price: totalAmount,
		});
	};

	const handleCancel = () => {
		setisApplyPaymentMethodCheckbox(false);
		Mixpanel.track(MixpanelEvents.CANCEL_CHECKOUT, {
			cart_total_price: totalAmount,
		});
		toggle();
	};

	const handlePrintReceipt = () => {
		const receiptIds = printDetails.paymentId;
		printReceipt(String(receiptIds), printDetails.customerId);
	};

	const sendReceiptAction = (contactMethod: 'email' | 'sms', sendAddress: string) => {
		handleSendReceipt([printDetails.paymentId], contactMethod, sendAddress);
	};

	const handleAmountChange = (value: number) => {
		setAmount(value);
		onSetAmount(value);
	};

	const handleSetSelectedCard = (paymentMethod?: UserPaymentMethod) => {
		setSelectedFullCard(paymentMethod);
		if (!isTriggerSwipe) {
			setFee(fee => findFeeSelectedStripePaymentMethod(paymentMethods, paymentMethod) ?? fee);
		}
		onSelectPaymentMethod(paymentMethod, payments?.length > 0 ? payments.length : 1);
	};

	const handleNewCardAction = (paymentMethod: UserPaymentMethod, type: string) => {
		if (initialStep === EChargeInnerStep.NEW_CARD) {
			toggle();
			return;
		}

		setMethod(prevStep);
		setStep(prevStep);
		setLoader(false);
		setSelectedCard(paymentMethod.paymentMethodId);
		handleSetSelectedCard(paymentMethod);
		setPaymentMethodsIfUserExists(PaymentMethodTypeEnum.CARD);
	};

	const handleOnScheduledSubmit = (options?: RequestInit) => {
		return paymentApi
			.schedulePayments({
				userId: userId || anonymous,
				invoiceId,
				organizationId: organizationId,
				payments: payments.map(payment => ({ ...payment, price: payment.price })),
				paymentMethodType: method === EChargeInnerStep.NEW_CARD ? PaymentMethodTypeEnum.CARD : method,
				payemntMethodId: selectedCard,
			}, options)
			.then(() => {
				toggle();
			});
	};

	const handleAnonPay = async (paymentMethod: UserPaymentMethod, type: string) => {
		if (!customer?.id || customer.entityId === anonymous) {
			setMethod(type as TChargeSteps);
			if (paymentMethod) {
				handleSetSelectedCard(paymentMethod);
				paymentAction(paymentMethod.paymentMethodId, type);
			} else {
				handleError(labels.error.noPaymentMethodFound);
			}
		}
	};

	const showBackButton = useMemo(() => {
		return (
			(onBackButton && step === '') ||
			(method !== '' &&
				!['', EChargeStatus.COMPLETED, EChargeInnerStep.SCHEDULED_CHARGE].includes(step) &&
				!isCompleted &&
				!isError &&
				!isScheduled &&
				initialStep !== EChargeInnerStep.NEW_CARD)
		);
	}, [step, method, isCompleted, isError, isScheduled, onBackButton]);

	const actionDisabled = useMemo(() => {
		if (method === '') return true;
		if (step === EChargeInnerStep.NEW_CARD && selectedCard === NEW_CARD && !isAllMandatoryFieldsValidated) return true;
		if (initialStep !== EChargeInnerStep.NEW_CARD && (!amount || amount <= 0)) return true;
		if (step !== '' && selectedCard === '' && documentNumber === '') return true;

		return (
			step === '' &&
			(amount > totalAmount ||
				(method === PaymentMethodTypeEnum.BALANCE && customer?.storedCredit > 0 && amount > customer?.storedCredit))
		);
	}, [amount, method, step, selectedCard, documentNumber, customer?.storedCredit, isAllMandatoryFieldsValidated]);

	const Stepper = useMemo(() => {
		setLoader(false);
		switch (step) {
			case '':
				return (
					<SelectMethod
						isAmountEditable={isAmountEditable}
						totalAmount={totalAmount}
						amount={amount}
						setAmount={handleAmountChange}
						method={method}
						setMethod={setPaymentMethod}
						customerBalance={!userId || userId === anonymous ? undefined : customerClone?.storedCredit}
						paymentMethods={paymentMethods}
						disableBalance={flowType === FlowType.SCHEDULED}
					/>
				);
			case EChargeInnerStep.SWIPE:
			case PaymentMethodTypeEnum.CARD:
				return (
					<CreditCard
						cards={userId ? creditCards : []}
						amount={amount}
						currency={'usd'}
						setMethod={setMethod}
						userId={userId || anonymous}
						selectedCard={selectedCard}
						setSelectedCard={setSelectedCard}
						isAnonymous={!userId}
						triggerPay={isTriggerSwipe}
						paymentAction={paymentAction}
						handleError={handleError}
						setFullSelectedCard={handleSetSelectedCard}
						customer={customerClone}
					/>
				);
			case EChargeInnerStep.NEW_CARD:
				return (
					<NewCard
						userId={userId || anonymous}
						trigger={triggerForNewCardSubmission}
						onSubmitAction={
							!customerClone?.id || customerClone.entityId === anonymous ? handleAnonPay : handleNewCardAction
						}
						handleError={handleError}
						organizationId={organizationId}
						onIsValidated={validated => setIsAllMandatoryFieldsValidated(validated)}
					/>
				);
			case PaymentMethodTypeEnum.ACH:
				return (
					<ACH
						ACHMethods={ACHMethods}
						setSelectedCard={setSelectedCard}
						selectedCard={selectedCard}
						setFullSelectedCard={handleSetSelectedCard}
					/>
				);
			case PaymentMethodTypeEnum.CASH:
			case PaymentMethodTypeEnum.CHECK:
			case PaymentMethodTypeEnum.OTHER:
			case PaymentMethodTypeEnum.GIFT_CARD:
				return (
					<CashOrDocument
						type={step}
						setSelectedCard={setSelectedCard}
						selectedCard={selectedCard}
						totalAmount={amount}
						handleDocumentNumber={handleDocumentNumber}
					/>
				);
			case PaymentMethodTypeEnum.BALANCE:
			case EChargeStatus.COMPLETED:
				return (
					<Completed
						amount={amount}
						toggle={handleClose || toggle}
						handleClose={handleClose}
						actuallyPaid={actuallyPaid}
						handlePrint={alternativeHandlePrintReceipt || handlePrintReceipt}
						handleSend={alternativeHandleSendReceipt || sendReceiptAction}
						feeAmount={fee && feeAmount}
						customer={
							method === PaymentMethodTypeEnum.BALANCE
								? { ...customerClone, storedCredit: customer.storedCredit - amount }
								: customerClone
						}
						paymentMethod={selectedFullCard}
						documentId={documentNumber}
						paymentMethodType={method as PaymentMethodTypeEnum}
					/>
				);
			case EChargeStatus.ERROR:
				return <Error toggle={handleCancel} message={errorMessage} />;
			case EChargeInnerStep.SCHEDULED_CHARGE: {
				if (isScheduled && initialStep !== EChargeInnerStep.RESCHEDULE) {
					return (
						<Scheduled
							totalAmount={totalAmount + feeAmount}
							toggle={handleCancel}
							userId={userId || anonymous}
							invoiceId={invoiceId}
							hasMembershipLine={hasMembershipLine}
							organizationId={organizationId}
							totalToCharge={amount}
						/>
					);
				} else {
					return (
						<AddSchedulePayment
							ACHMethods={ACHMethods}
							state={addScheduleState}
							setState={setScheduleState}
							setMethod={(val: TChargeSteps) => {
								setMethod(val);
								handleStep(val);
							}}
							toggle={toggle}
							totalAmount={totalAmount}
							setPayments={payments => {
								setPayments(payments);
								setStep(method);
							}}
						/>
					);
				}
			}
			case EChargeInnerStep.SCHEDULED:
				return <ConfirmScheduled toggle={toggle} payments={payments} onSubmit={handleOnScheduledSubmit} />;
			case EChargeInnerStep.RESCHEDULE: {
				return (
					<Reschedule
						toggle={handleCancel}
						payments={payments}
						setMethod={method => {
							setMethod(method);
						}}
					/>
				);
			}

			default:
				return <div>oops</div>;
		}
	}, [
		step,
		method,
		amount,
		selectedCard,
		triggerForNewCardSubmission,
		isTriggerSwipe,
		errorMessage,
		totalAmount,
		ACHMethods,
		creditCards,
		paymentMethods,
		payments,
		anonymous,
		customerClone,
	]);

	useEffect(() => {
		if (
			[EChargeInnerStep.NEW_CARD, EChargeInnerStep.SCHEDULED_CHARGE, EChargeInnerStep.SWIPE].includes(
				method as EChargeInnerStep
			)
		) {
			setStep(method);
			if (method === EChargeInnerStep.NEW_CARD) {
				Mixpanel.track(MixpanelEvents.START_ADD_NEW_CARD, {
					cart_total_price: totalAmount,
				});
			}
		}
	}, [method]);

	useEffect(() => {
		if (isScheduled && initialStep !== EChargeInnerStep.RESCHEDULE) {
			setMethod(EChargeInnerStep.SCHEDULED_CHARGE);
		}
	}, [isScheduled]);

	const updateTitles = (mainTitle: string) => {
		setMainTitle(mainTitle);
		updateSubTitle();
	};

	const updateTitlesAndStep = ({
		mainTitle,
		prevStep = '',
		method,
	}: {
		mainTitle: string;
		prevStep?: TChargeSteps;
		method?: PaymentMethodTypeEnum;
	}) => {
		updateTitles(mainTitle);
		setPrevStep(prevStep);
		method && setMethod(method);
	};

	const handleStep = (step: TChargeSteps) => {
		const methodLabels = labels.methods;
		if (step !== EChargeStatus.COMPLETED) {
			const formattedStep = [EChargeInnerStep.SWIPE, EChargeInnerStep.NEW_CARD].includes(step as any)
				? PaymentMethodTypeEnum.CARD
				: step;
			const findFee = paymentMethods?.find(pm => {
				if (pm.paymentMethodType === formattedStep) {
					if (pm.paymentMethodType !== PaymentMethodTypeEnum.CARD) return pm;
					if (
						pm.paymentMethodType === PaymentMethodTypeEnum.CARD &&
						CARD_SUBTYPE_WITH_FEES.includes(pm.subPaymentMethodType)
					)
						return pm;
				}
			})?.fee;

			setFee(findFee ?? undefined);
		}
		switch (step) {
			case EChargeInnerStep.SWIPE:
			case PaymentMethodTypeEnum.CARD:
				updateTitlesAndStep({ mainTitle: methodLabels.card, prevStep });
				setNewCCTrigger(false);
				break;
			case PaymentMethodTypeEnum.ACH:
				updateTitlesAndStep({ mainTitle: methodLabels[PaymentMethodTypeEnum.ACH], prevStep });
				break;
			case PaymentMethodTypeEnum.CASH:
			case PaymentMethodTypeEnum.CHECK:
			case PaymentMethodTypeEnum.OTHER:
			case PaymentMethodTypeEnum.GIFT_CARD:
				updateTitlesAndStep({ mainTitle: getPaymentMethodLabelByValue(step), method: step });
				break;
			case EChargeStatus.COMPLETED:
				updateTitles(labels.purchaseCompleted);
				break;
			case EChargeStatus.ERROR:
				break;
			case EChargeInnerStep.NEW_CARD:
				updateTitlesAndStep({ mainTitle: methodLabels.newCard, prevStep: PaymentMethodTypeEnum.CARD });
				setSelectedCard(NEW_CARD); //TODO: check if can be replaced with 'newCard' (enum...)
				resetPricing && resetPricing();
				break;
			case EChargeInnerStep.SCHEDULED:
			case EChargeInnerStep.RESCHEDULE: {
				if (fees?.length) {
					const updatedPayments = [];
					const paymentObjs = [...payments];
					paymentObjs.forEach((p: IPayment, idx: number) =>
						updatedPayments.push({
							...p,
							price: fees[idx] ? fees[idx].amount : p.price,
							totalPrice: fees[idx] ? fees[idx].totalPrice : p.price,
						})
					);
					setPayments(updatedPayments);
				}
				break;
			}
			case EChargeInnerStep.SCHEDULED_CHARGE:
				setMainTitle(labels.payment);
				setSubtitle(totalAmountTitle);
				setSelectedCard('');
				setSelectedFullCard(null);
				setMethod(initialStep);
				resetPricing && resetPricing();
				break;
			case '':
				setMainTitle(labels.payment);
				setSubtitle(totalAmountTitle);
				setSelectedCard('');
				setSelectedFullCard(null);
				resetPricing && resetPricing();
				break;
		}
	};

	const handleBackButton = () => {
		onBackButton && onBackButton(step);
		const prev = prevStep === step ? initialStep : prevStep;
		setMethod(prev);
		setStep(prev);
		if (prev === '') {
			setSelectedCard('');
			handleTrackBackButton();
		}
	};

	useEffect(() => {
		handleStep(step);
	}, [step]);

	const validateStepInSteps = (stepToValidate: TChargeSteps, steps: TChargeSteps[]): boolean => {
		return steps.includes(stepToValidate);
	};

	const displayApplyCheckbox =
		shouldDisplayApplyCheckbox && validateStepInSteps(step, [PaymentMethodTypeEnum.CARD, PaymentMethodTypeEnum.ACH]);
	const isApplyDisabled =
		!hasMembershipLine && (isApplyPaymentMethodDisabled || loader || isProcessing || actionDisabled);
	return (
		<div data-aid="charge" css={{ ...flexCol, width: '100%' }}>
			{showBackButton && (
				<div data-aid="charge-back" css={backButtonCss}>
					<SimpleButton onClick={handleBackButton}>
						<ReactSVG src="assets/media/icons/arrow-left.svg" css={iconCss} />
						{labels.back}
					</SimpleButton>
				</div>
			)}
			{showTitle && (
				<TitleSection
					isError={isError}
					isCompleted={isCompleted}
					step={step}
					mainTitle={mainTitle}
					initialLabels={initialLabels}
					showFeeWarning={showFeeWarning}
					fee={fee}
					method={method}
					subtitle={subtitle}
					secondSubtitle={secondSubtitle}
				/>
			)}

			{Stepper}

			{!isCompleted &&
				!isError &&
				![EChargeInnerStep.SCHEDULED_CHARGE, EChargeInnerStep.SCHEDULED, EChargeInnerStep.RESCHEDULE].includes(
					step as EChargeInnerStep
				) && (
					<Footer
						toggle={handleCancel}
						checkBox={
							<ApplyMethodCheckbox
								label={labels.paymentMethod.applyToFuturePayments}
								action={setisApplyPaymentMethodCheckbox}
								isDisabled={isApplyDisabled}
								checked={isApplyPaymentMethodCheckbox}
								isShown={displayApplyCheckbox}
								margin="2rem"
							/>
						}
						actionButtonText={chargeButtonText}
						actionButtonMethod={handleChargeClick}
						isActionButtonDisabled={actionDisabled}
						isActionButtonProcessing={loader || isProcessing}
					/>
				)}
		</div>
	);
};

interface TitleSectionProps {
	isError: boolean;
	isCompleted: boolean;
	step: TChargeSteps;
	mainTitle: string;
	initialLabels?: Record<string, string>;
	showFeeWarning: boolean;
	fee: FeeDto;
	method: TChargeSteps;
	subtitle: string;
	secondSubtitle: string;
}

const TitleSection = ({
	isError,
	isCompleted,
	step,
	mainTitle,
	initialLabels,
	showFeeWarning,
	fee,
	method,
	subtitle,
	secondSubtitle,
}: TitleSectionProps) => {
	const labels = TranslationEn.payments;

	return (
		<div data-aid="charge-title">
			<Fragment>
				{!isError && !isCompleted && (
					<div css={topCss}>
						<div css={topTextCss}>
							{isCompleted && <ReactSVG src="assets/media/icons/check.svg" css={iconCss} />}
							<div css={topTextSectionCss}>
								<Typography type={ETypography.h4} color={ETypographyColor.primary}>
									{initialLabels?.title ?? mainTitle}
								</Typography>
								{showFeeWarning && fee && FULL_FEE_STEPS.includes(step as PaymentMethodTypeEnum | EChargeInnerStep) && (
									<div css={leftJustifyRowCss}>
										<div css={svgCss('20px', 'auto', colors_v2.tx_error)}>
											<BnIcon icon={Icons.conflict} />
										</div>
										<Typography type={ETypography.caption} color={ETypographyColor.primary} css={selectedMethodWidth}>
											{labels.selectMethodFeeWarning(
												String(fee?.percentageValue),
												fee?.fixValue > 0 ? Pricify(fee?.fixValue) : '',
												labels.methods[
													[EChargeInnerStep.SWIPE, PaymentMethodTypeEnum.CARD, EChargeInnerStep.NEW_CARD].includes(
														method as PaymentMethodTypeEnum | EChargeInnerStep
													)
														? PaymentMethodTypeEnum.CARD
														: method
												]?.toLowerCase()
											)}
										</Typography>
									</div>
								)}
								{showFeeWarning && !FULL_FEE_STEPS.includes(step as PaymentMethodTypeEnum) && (
									<Typography type={ETypography.caption} color={ETypographyColor.primary}>
										{labels.feeWarning}
									</Typography>
								)}
							</div>

							<div css={topTextSectionCss}>
								{secondSubtitle && (
									<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
										{secondSubtitle}
									</Typography>
								)}
								<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
									{subtitle}
								</Typography>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		</div>
	);
};
