import { CancellationStatusEnum, LineItems, MembershipStatusEnum, PaymentStatusEnum } from '@bondsports/types';
import { PAUSE_RELATED_STATUSES } from '../../consts';
import { MembershipMoreButtonOptionEnum } from '@app/react/types/membership';
import { IOption } from '@bondsports/utils';

export const getLineItemPaymentStatus = (lineItem: Partial<LineItems>) => {
	const { isRefunded, isVoided, paymentStatus } = lineItem;
	if (isRefunded) {
		return PaymentStatusEnum.REFUNDED;
	} else if (isVoided) {
		return PaymentStatusEnum.VOID;
	}
	return paymentStatus;
};

export const buildOptions = (
	cancellationStatus: CancellationStatusEnum,
	membershipStatus: MembershipStatusEnum,
	isAutoRenew: boolean,
	hasCancellationPermission: boolean,
	labels: any
): IOption[] => {
	const options: IOption[] = [
		// Adding Cancel Membership Option
		...(hasCancellationPermission ? buildCancelOption(cancellationStatus, labels.cancelMembership) : []),
	];
	// Adding Pause Membership Options
	const pauseOptions = buildPauseOptions(membershipStatus, isAutoRenew, labels);
	options.push(...pauseOptions);
	return options;
};

const buildCancelOption = (
	cancellationStatus: CancellationStatusEnum,
	cancelMembershipLabels: string
): IOption[] => {
	if (!cancellationStatus || cancellationStatus === CancellationStatusEnum.AUTO_RENEWAL) {
		return [{
			label: cancelMembershipLabels,  
			value: MembershipMoreButtonOptionEnum.CANCEL,  
		}];  
	}
	return [];
}

const buildPauseOptions = (	
	membershipStatus: MembershipStatusEnum,
	isAutoRenew: boolean,
	labels: {
		pauseMembership: string;
		editPause: string;
		undoPauseMembership: string;
	}
): IOption[] => {
	// We are not going to show any pause options if the membership is not auto-renew
	if (!isAutoRenew) {
		return [];
	}

	const pauseOptions: IOption[] = [		
		// Adding Create Or Edit Pause Membership Option
		PAUSE_RELATED_STATUSES.includes(membershipStatus)
			? {
				label: labels.editPause,
				value: MembershipMoreButtonOptionEnum.EDIT_PAUSE,
			  }
			: {
				label: labels.pauseMembership,
				value: MembershipMoreButtonOptionEnum.PAUSE,
			  }
		];
	// Add Undo Pause Membership Option
	if (membershipStatus === MembershipStatusEnum.PAUSE_PENDING) {
		pauseOptions.push({
			label: labels.undoPauseMembership,
			value: MembershipMoreButtonOptionEnum.UNDO_PAUSE,
		});
	}
	return pauseOptions;
};
