import * as React from 'react';
import { TranslationEn } from '@assets/i18n/en';
import {
	CancellationStatusEnum,
	IExpandedMembershipMemberData,
	MembershipStatusEnum,
	PaymentStatusEnum,
} from '@bondsports/types';
import { Tag } from '@bondsports/utils';

import { getRenwalTitle } from '../../../memberships/utils/utils';
import { ColumnsIDsEnum, IMembershipRow } from '../../../../types/membership';
import { BOdocumentsEnum } from '../../../../types/enums';
import { getLineItemPaymentStatus } from './utils';

const labels = TranslationEn.memberships;
const tabLabels = TranslationEn.customers.membershipTab;

const getCancellationTagComponent = (cancellationStatus: CancellationStatusEnum) => {
	const { autoRenewalCancel } = labels.tags;
	if (cancellationStatus === CancellationStatusEnum.AUTO_RENEWAL) {
		return <Tag title={autoRenewalCancel} color={'red'} />;
	}
	return null;
};

const checkIfMoreButtonDisabled = (membershipStatus: MembershipStatusEnum) => {
	const { CANCELLED, EXPIRED } = MembershipStatusEnum;
	const isDisabled = membershipStatus === CANCELLED || membershipStatus === EXPIRED;
	return isDisabled;
};

export const createMembershipRows = (data: IExpandedMembershipMemberData) => {
	const { membership, member, user, lineItem, invoice } = data;
	const { cancellationStatus } = member;
	const flattenMembership = {} as IMembershipRow;

	Object.keys(data).forEach(key => {
		if (key === BOdocumentsEnum.PAYMENT) {
			const shouldShowPayment = lineItem.paymentStatus !== PaymentStatusEnum.NOT_PAID;
			if (shouldShowPayment) {
				flattenMembership.paymentId = data[key].id;
			}
		}
		if (key === 'member') {
			flattenMembership.memberId = data[key].id;
		}
		if (key !== BOdocumentsEnum.INVOICE && key !== BOdocumentsEnum.PAYMENT) {
			const fields = Object.keys(data[key]);
			fields.forEach(field => {
				flattenMembership[field] = data[key][field];
			});
		}
	});

	const newProps = {
		customerName: `${user.firstName} ${user.lastName}`,
		renewal: getRenwalTitle(membership.durationMonths),
		invoiceId: invoice.id,
		paymentStatus: getLineItemPaymentStatus(lineItem),
		price: lineItem.price,
		withPaymentStatusIcon: true,
		TagComponent: cancellationStatus ? getCancellationTagComponent(cancellationStatus) : null,
		taggedColumnId: ColumnsIDsEnum.END_DATE,
		additionalDate: flattenMembership.cancelledAt,
		additionalDatePrfix: tabLabels.cancellationDatePrefix,
		more: {
			membershipStatus: flattenMembership.membershipStatus,
			hasPauseHistory: member.hasPauseHistory,
			cancellationStatus,
			isDisabled: checkIfMoreButtonDisabled(flattenMembership.membershipStatus),
			isAutoRenew: flattenMembership.isAutoRenew,
		},
	};
	delete flattenMembership.firstName;
	delete flattenMembership.lastName;

	return { ...flattenMembership, ...newProps };
};
