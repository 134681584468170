import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const TIME_FORMAT = 'hh:mm a';
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_HOUR = 60;
export const DEFAULT_NEW_SLOT_DURATION = 30;
export const DEFAULT_NEW_SLOT_HOUR_SIZE = 90;

const DEFAULT_HOUR_HEIGHT = 63;
const DEFAULT_HOUR_WIDTH = 82;

export let oneHourHeight = DEFAULT_HOUR_HEIGHT;
export let oneHourWidth = DEFAULT_HOUR_WIDTH;

export const setNewHourHeight = (height = DEFAULT_HOUR_HEIGHT) => {
	oneHourHeight = Math.max(height, DEFAULT_HOUR_HEIGHT);
};

export const setNewHourWidth = (width = DEFAULT_HOUR_WIDTH) => {
	oneHourWidth = Math.max(width, DEFAULT_HOUR_WIDTH);
};

export const calculateMarginFromMinutes = (minutes: number) => {
	return (minutes / 60) * oneHourHeight;
};

export const calculateMarginFromHours = (h: number) => {
	return h * oneHourHeight + 6;
};

export const calculateMinutesFromHeight = (divHeight: number) => {
	return (60 * divHeight) / oneHourHeight;
};

export const calculateMarginLeftFromMinutes = (minutes: number) => {
	return (minutes / 60) * oneHourWidth;
};

export const calculateMarginLeftFromHours = (h: number) => {
	return h * oneHourWidth;
};

export const calculateMinutesFromWidth = (width: number) => {
	return (60 * width) / oneHourWidth;
};

export const getHours = (startTime: string, endTime: string) =>
	dayjs(startTime).format(TIME_FORMAT) + ' - ' + dayjs(endTime).format(TIME_FORMAT);

export const getTimeFromMinutes = (minutes: number): string =>
	dayjs().minute(0).hour(0).add(minutes, 'minutes').format(TIME_FORMAT);

export const isDateBetweenDates = (
	dateToVerify: string,
	startDate: string,
	endDate: string,
	format: string = 'DD/MM/YYYY'
): boolean => {
	// startDate <= dateToVerify || dateToVerify <= endDate
	const dateToVerifyFormat = dayjs.utc(dateToVerify).format(format);
	const startDateFormat = dayjs.utc(startDate).format(format);
	const endDateFormat = dayjs.utc(endDate).format(format);

	return (
		(dayjs(dateToVerifyFormat, format).isAfter(dayjs(startDateFormat, format)) ||
			dayjs(dateToVerifyFormat, format).isSame(dayjs(startDateFormat, format))) &&
		(dayjs(endDateFormat, format).isAfter(dayjs(dateToVerifyFormat, format)) ||
			dayjs(endDateFormat, format).isSame(dayjs(dateToVerifyFormat, format)))
	);
};

/*
 * need to understand if it is
 * A. all day or full day -> startDate is start of the day, endDate is end of the day
 * B. endDate is pass midnight -> start Date no change, endDate is set to end of the day
 * C. startDate is befor midnight -> end Date no change, startDate is set to start of the day
 * we need to validate the between the currentDate and (startDate or endDate) has a gap of a day
 */
export const isDayPass = (dateToVerify: string, dateToCompare): boolean => {
	const dateToVerifyStartOfDay = dayjs(dateToVerify).startOf('day');
	const dateToCompareStartOfDay = dayjs(dateToCompare).startOf('day');
	return dateToVerifyStartOfDay.diff(dateToCompareStartOfDay, 'day') > 1;
};

export const diffCalculation = (startDateTime: string, endDateTime: string): string => {
	// Calculate the total difference in minutes
	const dayJsStartDateTime = dayjs.utc(startDateTime);
	const dayJsEndDateTime = dayjs.utc(endDateTime);

	let totalMinutes = dayJsStartDateTime.isBefore(dayJsEndDateTime)
		? dayjs.utc(endDateTime).diff(dayjs.utc(startDateTime), 'minutes')
		: dayjs.utc(startDateTime).diff(dayjs.utc(endDateTime), 'minutes');

	// Define unit conversions
	const minutesInAnHour = 60;
	const minutesInADay = 1440; // 24 * 60
	const minutesInAWeek = 10080; // 7 * 1440

	// Calculate each unit
	const weeks = Math.floor(totalMinutes / minutesInAWeek);
	totalMinutes %= minutesInAWeek;

	const days = Math.floor(totalMinutes / minutesInADay);
	totalMinutes %= minutesInADay;

	const hours = Math.floor(totalMinutes / minutesInAnHour);
	totalMinutes %= minutesInAnHour;

	const minutes = totalMinutes;

	// Construct a human-readable output
	const result: string[] = [];
	if (weeks) result.push(`${weeks}w`);
	if (days) result.push(`${days}d`);
	if (hours) result.push(`${hours}h`);
	if (minutes) result.push(`${minutes}min`);

	return result.length > 0 ? result.join(' ') : '0 minutes';
};

export const getCombinedDateTime = (date: string, time: string): Date => {
	const combinedDateTime = dayjs.utc(`${dayjs(date).format('YYYY-MM-DD')}T${time}`, 'YYYY-MM-DDTHH:mm:ss');
	return new Date(combinedDateTime.format('YYYY-MM-DDTHH:mm:ss[Z]'));
};

export const getCombinedDateTimeAsString = (date: string, time: string): string => {
	if (dayjs(time).isValid()) {
		return dayjs(time).format('YYYY-MM-DDTHH:mm:ss.SSS').toString();
	}
	const combinedDateTime = dayjs.utc(`${dayjs(date).format('YYYY-MM-DD')}T${time}`, 'YYYY-MM-DDTHH:mm:ss');
	return combinedDateTime.toString();
};

export const isSameDate = (date: string, dateToCompare: string): boolean => {
	return dayjs.utc(date).isSame(dayjs.utc(dateToCompare), 'day');
};

export const getLowestTime = (time: string, overallTime?: string): string => {
	if (!overallTime) {
		return time;
	}

	return dayjs.utc(`2021-01-01T${time}`).isBefore(dayjs.utc(`2021-01-01T${overallTime}`)) ? time : overallTime;
};

export const getMaxTime = (time: string, overallTime?: string): string => {
	if (!overallTime) {
		return time;
	}

	return dayjs.utc(`2021-01-01T${time}`).isAfter(dayjs.utc(`2021-01-01T${overallTime}`)) ? time : overallTime;
};
