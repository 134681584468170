/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Fragment, useMemo, useCallback } from 'react';

import { Typography } from '../atoms';
import { BnIcon } from '../components/icons_v2';
import { Conflict } from '../components/icons_v2/all/Conflict';
import {
	PaymentStatusPaid,
	PaymentStatusPartially,
	PaymentStatusUnpaid,
} from '../organisms/bo_calendar_slot/icons/paymentStatusPartiallyPaid';
import { ReservationStatusApproved } from '../organisms/bo_calendar_slot/icons/reservationStatusApproved';
import { ReservationStatusPending } from '../organisms/bo_calendar_slot/icons/reservationStatusPending';
import { DotCss, genericIconCss, iconsContainerCss, conflictIconCss } from '../organisms/bo_calendar_slot/style';
import { PaymentStatusEnum, ReservationStatusEnum, SlotTypeEnum } from '../organisms/bo_calendar_slot/types';
import { ETypography, ETypographyColor, Icons } from '../types';
import { ResourceNameTypeEnum } from '../types/enums';
import { getCombinedDateTimeString, MIDNIGHT } from '../lib/timeUtils';
import { DateTimeFormats } from '@bondsports/date-time';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const frequencyMapper = {
	daily: 'Daily',
	weekly: 'Weekly',
	monthly: 'Monthly',
};

//REFACTOR REQUIRED - extract components from the hook
export const useSlot = ({ state, isConflict = false }: { state: any; isConflict?: boolean }) => {
	const isDraft = !!state?.isDraft;
	const {
		startTime,
		endTime,
		isShort,
		isEditable,
		diff,
		shortDisplay,
		isRental,
		maintDislpay,
		startDate,
		endDate,
		dayDiff,
		isAllDay,
	} = useMemo(() => {
		const { startTime: slotStartTime, endTime: slotEndTime, startDate, endDate, event } = state;
		const isDiagonal = isDraft ? true : state?.isInternal ? true : false;
		const startTime = slotStartTime ? slotStartTime : startDate;
		const endTime = slotEndTime ? slotEndTime : endDate;
		const diff = dayjs(getCombinedDateTimeString(endDate, endTime)).diff(
			getCombinedDateTimeString(startDate, startTime),
			'minutes'
		);
		const isShort = diff <= 30;
		const shortDisplay = `${diff} min`;
		const maintDislpay = `${dayjs.utc(startTime, DateTimeFormats.H24_WITH_SECONDS).format('h:mma')} ${diff} min`;
		const isEditable = ResourceNameTypeEnum.RESERVATION === event?.parentType && !state?.parentSlotId;
		const isRental = state.slotType === SlotTypeEnum.EXTERNAL;
		const dayDiff: number = Math.abs(dayjs(state.endDate, DateTimeFormats.YYYY_MM_DD).diff(state.startDate, 'days'));
		const isAllDay: boolean = state.endTime === MIDNIGHT && dayDiff === 1;

		return {
			isDiagonal,
			startTime,
			endTime,
			isShort,
			isEditable,
			diff,
			shortDisplay,
			isRental,
			maintDislpay,
			startDate,
			endDate,
			dayDiff,
			isAllDay,
		};
	}, [state]);

	const ShortTime = `${dayjs
		.utc(startTime, DateTimeFormats.H24_WITH_SECONDS)
		.format(DateTimeFormats.H12_WITH_MINUTES)}-${dayjs
		.utc(endTime, DateTimeFormats.H24_WITH_SECONDS)
		.format(DateTimeFormats.H12_AMPM)}`;

	const LongTime = `${dayjs
		.utc(startTime, DateTimeFormats.H24_WITH_SECONDS)
		.format(DateTimeFormats.H12_WITH_MINUTES)}-${dayjs
		.utc(endTime, DateTimeFormats.H24_WITH_SECONDS)
		.format(DateTimeFormats.H12_AMPM)}`;

	const startTimeStr = `${
		dayjs.utc(startTime, DateTimeFormats.H24_WITH_SECONDS).format('mm') === '00'
			? dayjs.utc(startTime, DateTimeFormats.H24_WITH_SECONDS).format('hha')
			: dayjs.utc(startTime, DateTimeFormats.H24_WITH_SECONDS).format('hh:mma')
	}`;

	const Dot = () => <span className="dot" css={DotCss} />;

	const TimeDisplayComponent = useCallback(
		// Todo: remove false once we have the deifnition of the dayDiff
		(displayedTime: string) => (
			<Typography color={ETypographyColor.primary} type={ETypography.overline}>
				{displayedTime}
				{false && !isAllDay && dayDiff ? ` +${dayDiff}` : ''}
			</Typography>
		),
		[isAllDay, dayDiff]
	);

	const TimeShortDisplayComponent = useCallback(() => {
		const isMaintenance: boolean = state.slotType === SlotTypeEnum.MAINTENANCE;
		const displayedTime: string = isMaintenance ? maintDislpay : ShortTime;

		return TimeDisplayComponent(displayedTime);
	}, [state.slotType, maintDislpay, ShortTime, TimeDisplayComponent]);

	const TimeLongDisplayComponent = useCallback(() => {
		const isMaintenance: boolean = state.slotType === SlotTypeEnum.MAINTENANCE;
		const displayedTime: string = isMaintenance ? maintDislpay : LongTime;

		return TimeDisplayComponent(displayedTime);
	}, [state.slotType, maintDislpay, LongTime, TimeDisplayComponent]);

	const StartTime = useCallback(() => {
		return (
			<Typography color={ETypographyColor.primary} type={ETypography.overline}>
				{startTimeStr}
			</Typography>
		);
	}, [startTimeStr]);

	const formatDiffToString = (diff: number) => {
		const hours = Math.floor(diff / 60);
		const minutes = diff % 60;
		let str = '';
		if (hours > 0) {
			str += `${hours}h`;
		}

		if (minutes > 0) {
			if (str) {
				str += ` `;
			}
			str += `${minutes}m`;
		}

		return str;
	};

	const DiffTime = useCallback(() => {
		return (
			<Typography color={ETypographyColor.primary} type={ETypography.overline}>
				{formatDiffToString(diff)}
			</Typography>
		);
	}, []);

	const CustomerName = useCallback(() => {
		return (
			<Typography color={ETypographyColor.primary} type={ETypography.overline}>
				{state?.reservation?.customer?.name}
			</Typography>
		);
	}, []);

	const MaintenanceComponent = useCallback(() => {
		return (
			<Typography color={ETypographyColor.primary} type={ETypography.overline}>
				Maintenance
			</Typography>
		);
	}, []);

	const CircleIcon = useCallback(({ icon }: { icon: Icons }) => {
		return (
			<span className="circle-icon">
				<BnIcon icon={icon} />
			</span>
		);
	}, []);

	const Name = useCallback(({ size = 'M' }: { size: 'S' | 'M' }) => {
		return (
			<Typography
				color={ETypographyColor.primary}
				type={size === 'M' ? ETypography.captionAccented : ETypography.overlineAccented}
			>
				{state?.title}
			</Typography>
		);
	}, []);

	// to be filled
	const InternalSecondary = useCallback(() => {
		return <div data-aid="useSlot-hooks" />;
	}, []);

	const SpacerY = useCallback(({ height }: { height: number }) => {
		return <div style={{ height: height + 'px', width: '100%', display: 'block' }} />;
	}, []);

	const SpacerX = useCallback(({ width }: { width: number }) => {
		return <div style={{ width: `${width}px`, height: '100%', display: 'block' }} />;
	}, []);

	const IconsContainer = useCallback(
		({ hidePaymentIcons = false, heightModifier = 0 }: { hidePaymentIcons?: boolean; heightModifier?: number }) => {
			return (
				<div css={iconsContainerCss(heightModifier)}>
					{!hidePaymentIcons && !isDraft && isRental && (
						<Fragment>
							{state?.paymentStatus === PaymentStatusEnum.FULLY_PAID && <PaymentStatusPaid />}
							{state?.paymentStatus === PaymentStatusEnum.PARTIAL_PAYMENT && <PaymentStatusPartially />}
							{state?.paymentStatus === PaymentStatusEnum.NOT_PAID && <PaymentStatusUnpaid />}
							<SpacerX width={4} />
							{state?.approvalStatus === ReservationStatusEnum.APPROVED ? (
								<ReservationStatusApproved />
							) : (
								<ReservationStatusPending />
							)}
						</Fragment>
					)}
					{isConflict && (
						<div css={conflictIconCss}>
							<SpacerX width={4} />
							<Conflict />
						</div>
					)}
				</div>
			);
		},
		[]
	);

	const Frequency = useCallback(() => {
		return (
			<Fragment>
				{state?.series?.frequency && (
					<div style={{ marginLeft: '19px', marginTop: '3px' }}>
						<Typography color={ETypographyColor.primary} type={ETypography.caption}>
							{frequencyMapper[state?.series?.frequency]}
						</Typography>
					</div>
				)}
			</Fragment>
		);
	}, []);

	const PrivateIndicator = useCallback(() => {
		return (
			<Fragment>
				{state?.isPrivate && (
					<Fragment>
						<SpacerX width={4} />
						<BnIcon className="main" icon={Icons.lock_filled} css={genericIconCss(12)} />
					</Fragment>
				)}
			</Fragment>
		);
	}, []);

	return {
		TimeShortDisplayComponent,
		TimeLongDisplayComponent,
		Dot,
		CustomerName,
		isDraft,
		MaintenanceComponent,
		Name,
		SpacerX,
		SpacerY,
		isShort,
		DiffTime,
		InternalSecondary,
		PrivateIndicator,
		IconsContainer,
		isEditable,
		startTime,
		Frequency,
		endTime,
		StartTime,
	};
};
