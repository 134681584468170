/** @jsx jsx */
import React, { useState, useEffect, useRef, Fragment, useCallback, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { MembershipStatusIndication } from '@bondsports/utils';
import {
	MembershipFilterByPropertyEnum,
	MembershipOrderByPropertyEnum,
	OrderByEnum,
} from '@bondsports/types';
import { useQueryClient } from '@tanstack/react-query'

import { EmptyTab } from '../EmptyTab';
import { ItemsPerPageCalculator, Table } from '../../../shared/NewTable';
import { CustomSelect } from '../../customSelect';
import { Input } from '../../../shared/Input';
import { MembershipFilter } from './MembershipsFilter';
import { CancelMembershipModal } from '../../../memberships/CancelMembershipModal';
import { RefundCustomerModal } from '../../../memberships/RefundCustomerModal';
import { PauseMembershipModal } from '../../../memberships/PauseMembershipModal';

import { useMembershipTab } from '../../../../hooks/memberships/useMembershipTab';
import { useToggle } from '../../../../hooks/useToggle';
import { useDebounce } from '../../../../hooks/useDebouncedSearch';
import { useNavigation } from '../../../../hooks/useNavigation';
import { useMembershipActions } from '@app/react/hooks/memberships/useMembershipActions';
import { membershipApi } from '../../../../lib/api/membershipApi';
import { createMembershipRows } from './membershipTabUtils';

import { flex } from '../../../../styles/utils';
import { AlignType } from '../../../shared/NewTable/types';
import {
	ColumnsIDsEnum,
	IMembershipRow,
	IMoreButtonState,
	MembershipMoreButtonOptionEnum,
	MembershipTabSortableColumnsEnum,
	TMembershipFilterValue,
} from '../../../../types/membership';
import { ERoutePaths as EPath } from '../../../../types/navigation';
import { TranslationEn } from '@assets/i18n/en';
import { ColumnType } from '@app/react/components/shared/NewTable/HeaderRow';
import { IPauseDetails } from '@app/react/forms/membership/pause/createPause/types';
import { mapValuesToCreatePauseDto, mapValuesToUpdatePauseDto } from '@app/react/forms/membership/pause/createPause/utils';
import { MembershipMoreOptions } from './MembershipMoreOptions';
import { UndoPauseModal } from '@app/react/components/memberships/PauseMembershipModal/UndoPauseModal';
import { useUndoPauseModal } from '@app/react/hooks/memberships/useUndoPauseModal';
import usePermissions from '@app/react/hooks/permissions/usePermissions';

const containerCss = css`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	input {
		margin-bottom: 0 !important;
	}
`;

const filterContainerCss = css`
	${flex}
	justify-content: flex-start;
	gap: 16px;
	margin: 20px 2rem;
`;

const tableContainerCss = css`
	height: 500px;
	flex-grow: 1;
	margin: 1rem 2rem 2rem 2rem;
`;

const membershipsStatusCss = css`
	margin-left: 2rem;
`;

type TDocumentSegment = EPath.INVOICES | EPath.PAYMENTS;

interface Props {
	customerState: any;
	isFamily: boolean;
}

export const MembershipTab = ({ customerState, isFamily }: Props) => {
	const [isProcessing, setIsProcessing] = useState(true);
	const [searchFilter, setSearchFilter] = useState('');
	const [tablePage, setPage] = useState(0);
	const [memberships, setMemberships] = useState<IMembershipRow[]>([]);
	const [meta, setMeta] = useState({});
	const [skipFirstFetch, setSkipFirstFetch] = useState(true);
	const tableContainerRef = useRef(null);
	const location = useLocation();
	const debouncedValue = useDebounce(searchFilter, 300);
	const { itemsPerPage } = ItemsPerPageCalculator(tableContainerRef);
	const [isRefundShowing, refundToggle] = useToggle();
	const { pushNavigation, ngNavigate } = useNavigation();
	const { organizationId, id: customerId } = customerState;
	const queryClient = useQueryClient();

	const { checkPermissions } = usePermissions();
	const {
		sortProperties,
		sortOrder,
		handleSortColumn,
		handleUpdateFilters,
		selectedFilters,
		filters,
		statusFilter,
		paymentFilter,
		renewalFilter,
		setStatusFilter,
		setPaymentFilter,
		setRenewalFilter,
		handleFilterForActiveStatus,
		handleNoMembershipsError,
		noMemberships,
	} = useMembershipTab();

	const {
		setSelectedUser,
		handleCancelIconClick,
		selectedMember,
		isCancelMembershipShowing,
		isPauseMembershipShowing,
		isMemberInFamilyMembership,
		cancellationReason,
		isProcessingActions,
		isPauseHistoryLoading,
		setCancellationReason,
		cancelToggle,
		handleCancel,
		pauseId,
		setPauseId,
		handlePause,
		pauseHistory,
		handleEditPause,
		handleUndoPause,
		pauseToggle,
		handlePauseIconClick,
	} = useMembershipActions(organizationId);

	const { displayUndoPauseModal, toggleUndoPauseModal: handleUndoPauseClicked } = useUndoPauseModal();

	const invalidatePauseHistoryCache = (memberId?: number) => {
		if (!memberId) {
			return;
		};

		queryClient.invalidateQueries(['pauseHistory', organizationId, memberId]);
	}
	const getUpdatedMembershipsCallback = useCallback(async () => {
		invalidatePauseHistoryCache(selectedMember?.memberId);
		await getMemberships({
			searchTerm: searchFilter,
			filterBy: selectedFilters,
			filterFields: filters,
			sortBy: sortProperties,
			page: tablePage + 1,
			sortOrder,
		});
	}, [searchFilter, tablePage, selectedFilters, selectedMember?.memberId, filters, sortProperties, sortOrder]);

	const labels = TranslationEn.customers.membershipTab;

	const getMemberships = async ({
		searchTerm,
		filterBy,
		filterFields,
		sortBy,
		sortOrder,
		page = tablePage + 1,
	}: {
		searchTerm?: string;
		filterBy?: MembershipFilterByPropertyEnum[];
		filterFields?: TMembershipFilterValue[];
		sortBy?: MembershipOrderByPropertyEnum[];
		sortOrder?: OrderByEnum[];
		page?: number;
	}) => {
		setIsProcessing(true);
		const res = await membershipApi.getMembershipsByCustomerId({
			customerId,
			organizationId,
			itemsPerPage,
			page,
			properties: sortBy,
			sortOrder,
			filterBy,
			filterValues: filterFields,
			searchBy: searchTerm,
		});
		const memberships = handleNoMembershipsError(res, debouncedValue);
		if (memberships) {
			const newRows = memberships.data?.map(m => createMembershipRows(m));
			setMemberships(newRows);
			setMeta(memberships.meta);
		}

		setIsProcessing(false);
	};

	const redirectMembership = (id: number) => {
		//client/memberships#/dashboard/188
		ngNavigate(EPath.MEMBERSHIPS, `${EPath.DASHBOARD}/${id}`);
	};

	const redirectToInvoiceOrPayment = (segment: TDocumentSegment, id: number) => {
		const { pathname } = location; //customer/256460/membership
		const documentPath = pathname.replace(EPath.MEMBERSHIP, segment);
		const newPath = `${documentPath}/${id}${segment === EPath.INVOICES ? `/${EPath.DETAILS}` : ''}`; //customer/256460/invoices/185595/details
		pushNavigation(newPath);
	};

	const onCancelMembership = async (isImmediate: boolean) => {
		const cancelCallBack = async () => {
			refundToggle();
			await getUpdatedMembershipsCallback()
		};
		handleCancel({ isImmediate, organizationId, callback: cancelCallBack });
	};

	const commonPauseData = useMemo(() => ({
		organizationId,
		familyMembership: isMemberInFamilyMembership,
		pausedMemberId: selectedMember?.memberId,
		callback: getUpdatedMembershipsCallback,
	}), [organizationId, isMemberInFamilyMembership, selectedMember?.memberId]);

	const handleUnpauseAction = () => {
		handleUndoPause(commonPauseData);
		handleUndoPauseClicked();
	}

	const onSubmitPause = (values: IPauseDetails) => {
		if (!pauseId) {
			handleCreatePause(values);
		} else {
			handleUpdatePause(values);
		}
	}

	const handleCreatePause = (values: IPauseDetails) => {
			const pauseData = mapValuesToCreatePauseDto(values);
			handlePause({
				...commonPauseData,
				pauseData: pauseData,
			});
	};

	const handleUpdatePause = (values: IPauseDetails) => {
		const updateableFields = mapValuesToUpdatePauseDto(values, selectedMember.membershipStatus);
		handleEditPause({
			...commonPauseData,
			editPauseData: updateableFields,
		});
	};

	const handleMoreMenu = (option: MembershipMoreButtonOptionEnum, tabRow: IMembershipRow) => {
		switch (option) {
			case MembershipMoreButtonOptionEnum.CANCEL:
				handleCancelIconClick(tabRow);
				break;
			case MembershipMoreButtonOptionEnum.PAUSE:
				handlePauseIconClick(tabRow);
				break;
			case MembershipMoreButtonOptionEnum.EDIT_PAUSE:
				handlePauseIconClick(tabRow, MembershipMoreButtonOptionEnum.EDIT_PAUSE);
				break;
			case MembershipMoreButtonOptionEnum.UNDO_PAUSE:
				handleUndoPauseClicked();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const parsed = queryString.parse(location.search.replace('?', ''));
		if (parsed.page) {
			setPage(Number(parsed.page) - 1);
		}
		return () => setPage(0);
	}, [location]);

	useEffect(() => {
		handleUpdateFilters();
	}, [statusFilter, paymentFilter, renewalFilter]);

	useEffect(() => {
		if (!skipFirstFetch) {
			handleFilterForActiveStatus();
			const asyncFetch = async () => {
				await getMemberships({
					searchTerm: debouncedValue,
					filterBy: selectedFilters,
					filterFields: filters,
					sortBy: sortProperties,
					sortOrder,
				});
			};
			asyncFetch();
		}
		setSkipFirstFetch(false);
	}, [debouncedValue, tablePage, itemsPerPage, sortProperties, sortOrder, selectedFilters, filters]);

	const canViewMemberships = checkPermissions(['pages.memberships.view']);
	const columns: ColumnType[] = [
		{
			id: ColumnsIDsEnum.NAME,
			label: 'Membership',
			type: 'longString',
			isSortable: true,
			isUnderlined: canViewMemberships,
			action: (canViewMemberships ? ({ membershipId }) => redirectMembership(membershipId) : undefined),
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.MEMBERSHIP, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.START_DATE,
			label: 'Start date',
			type: 'datetime',
			isSortable: true,
			hideHoursDisplay: true,
			isInitialAscending: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.STARTDATE, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.END_DATE,
			label: 'End date',
			type: 'datetime',
			isSortable: true,
			hideHoursDisplay: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.ENDDATE, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.RENEWAL,
			label: 'Renewal',
			type: 'string',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.RENEWAL, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.PRICE,
			label: 'Payment',
			type: 'currency',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.PAYMENT_STATUS, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.INVOICE_ID,
			label: 'Invoice',
			type: 'invoice',
			isSortable: false,
			action: (row: IMembershipRow) => redirectToInvoiceOrPayment(EPath.INVOICES, row.invoiceId),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.PAYMENT_ID,
			label: 'Receipt',
			type: 'invoice',
			isSortable: false,
			action: (row: IMembershipRow) => redirectToInvoiceOrPayment(EPath.PAYMENTS, row.paymentId),
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.STATUS,
			label: 'Status',
			type: 'custom',
			isSortable: true,
			isInitialAscending: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.MEMBERSHIP_STATUS, isAsc),
			component: (v, row: IMembershipRow) => {
				return (
					<div css={membershipsStatusCss}>
						<MembershipStatusIndication membershipStatus={row.membershipStatus} />
					</div>
				);
			},
			styling: {
				align: AlignType.LEFT,
			},
		},
		{
			id: ColumnsIDsEnum.MORE,
			label: '',
			type: 'custom',
			isSortable: false,
			action: (row: IMembershipRow) => setSelectedUser(row),
			component: (state: IMoreButtonState) => {
				return (<MembershipMoreOptions state={state} handleMoreMenu={handleMoreMenu} selectedMember={selectedMember} />);
			}
		},
	];
	if (isFamily) {
		const customerNameColumn: ColumnType = {
			id: ColumnsIDsEnum.CUSTOMER_NAME,
			label: 'Customer Name',
			type: 'longString',
			isSortable: true,
			onSort: (isAsc: boolean) => handleSortColumn(MembershipTabSortableColumnsEnum.NAME, isAsc),
			styling: {
				align: AlignType.LEFT,
			},
		};
		columns.unshift(customerNameColumn);
	}

	return (
		<Fragment>
			{noMemberships && !isProcessing ? (
				<EmptyTab noRowsObj={noMemberships} labels={labels} />
			) : (
				<Fragment>
					<div css={containerCss}>
						<div css={filterContainerCss}>
							<Input
								data-aid="MembershipTab-input-search"
								search
								placeholder={labels.searchPlaceholder}
								value={searchFilter}
								onChange={e => setSearchFilter(e.target.value)}
							/>
							<CustomSelect
								title={labels.filter}
								content={
									<MembershipFilter
										statusFilter={statusFilter}
										paymentFilter={paymentFilter}
										renewalFilter={renewalFilter}
										setStatus={setStatusFilter}
										setPayment={setPaymentFilter}
										setRenewal={setRenewalFilter}
									/>
								}
							/>
						</div>
						<div data-aid="MembershipTab-table" ref={tableContainerRef} css={tableContainerCss}>
							<Table
								page={tablePage}
								meta={meta}
								rows={memberships}
								columns={columns}
								defaultSortColumn={'Status'}
								isLoading={!memberships.length}
								subject={EPath.MEMBERSHIPS}
								timezone="UTC"
							/>
						</div>
					</div>
					<CancelMembershipModal
						isModalShown={isCancelMembershipShowing}
						isProcessing={isProcessingActions}
						cancelledUser={selectedMember}
						cancellationReason={cancellationReason}
						handleCancel={onCancelMembership}
						setCancellationReason={setCancellationReason}
						cancelToggle={cancelToggle}
					/>
					<PauseMembershipModal
						isModalShown={isPauseMembershipShowing}
						isProcessing={isProcessingActions}
						selectedMember={selectedMember}
						organizationId={organizationId}
						handlePause={onSubmitPause}
						pauseId={pauseId}
						isLoadingHistory={isPauseHistoryLoading}
						pauseHistory={pauseHistory}
						setPauseId={setPauseId}
						pauseToggle={pauseToggle}
					/>
					<UndoPauseModal
						displayDeleteModal={displayUndoPauseModal}
						handleCancel={handleUndoPauseClicked}
						handleMainAction={handleUnpauseAction}
					/>
					<RefundCustomerModal
						isRefundShowing={isRefundShowing}
						refundToggle={refundToggle}
						fullName={selectedMember?.customerName}
						mainAction={() => redirectToInvoiceOrPayment(EPath.INVOICES, selectedMember.invoiceId)}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};
