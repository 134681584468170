/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { OptionsButton } from './OptionsButton';
import {
	IMembershipRow,
	IMoreButtonState,
	MembershipMoreButtonOptionEnum,
} from '../../../../types/membership';
import { TranslationEn } from '@assets/i18n/en';
import { buildOptions } from './utils';
import usePermissions from '@app/react/hooks/permissions/usePermissions';

interface MembershipMoreOptionsProps {
	state: IMoreButtonState;
    handleMoreMenu: (option: MembershipMoreButtonOptionEnum, tabRow: IMembershipRow) => void;
    selectedMember: IMembershipRow;
}

export const MembershipMoreOptions = ({ state, handleMoreMenu, selectedMember }: MembershipMoreOptionsProps) => {
	const { cancellationStatus, isDisabled, membershipStatus, isAutoRenew } = state;
	const labels = TranslationEn.customers.membershipTab.more;
	const { checkPermissions } = usePermissions();
    const options = buildOptions(cancellationStatus, membershipStatus, isAutoRenew, checkPermissions(['customers.memberships.cancel']), labels);

	return (
		<OptionsButton
			isDisabled={isDisabled}
			handleMoreMenu={handleMoreMenu}
			options={options}
			selectedMember={selectedMember}
		/>
	);
};
