/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Fragment, ReactElement } from 'react';
import { jsx } from '@emotion/react';
import { SkeletonWrapper } from '../../components/skeletonWrapper';
import { loadingDailyContainer, daiyHourSkeleton } from './styles';
import { EMPTY_SLOT_HEIGHT, EMPTY_SLOT_WIDTH, DAILY_SKELETON_ELEMENTS_COUNT } from '../utils/consts';
import { paddingBottomCss } from '../../styles/utils';

export const LoadingDailyContainer = () => {

		const elements: ReactElement[] = [];
		for (let i = 0; i < DAILY_SKELETON_ELEMENTS_COUNT; i++) {
			elements.push(
				<Fragment>
					<SkeletonWrapper isLoading minHeight={EMPTY_SLOT_HEIGHT} minWidth={EMPTY_SLOT_WIDTH} key={`loading-daily-slot_first_${i}`}>
							<div css={daiyHourSkeleton} data-testid="loading-daily-hour-slot">mock</div>
					</SkeletonWrapper>
					<SkeletonWrapper isLoading minHeight={EMPTY_SLOT_HEIGHT} minWidth={EMPTY_SLOT_WIDTH} key={`loading-daily-slot_second_${i}`}>
							<div css={daiyHourSkeleton} data-testid="loading-daily-hour-slot">mock</div>
					</SkeletonWrapper>
					<div css={paddingBottomCss(60)} />
				</Fragment>
			);
		}

	return (
		<Fragment key="DailyViewLoading">
			<div css={loadingDailyContainer} data-aid='LoadingContainer-daily-day'>
				{elements}
			</div>
		</Fragment>
	);
};
